import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild, HostListener, ViewEncapsulation } from '@angular/core';
import { DatePipe } from '@angular/common';
import { EtsComponentRouterService } from '../ets-component-router-service/ets-component-router.service';
import { EtsConfigService } from '../ets-config-service/ets-config.service';
import { EtsListingData } from '../ets-listing-service/ets-listing-data';
import { EtsListingService } from '../ets-listing-service/ets-listing.service';
import { MatCalendar, MatCalendarCellClassFunction, MatDatepickerModule } from '@angular/material/datepicker';
import { NgxSpinnerService } from 'ngx-spinner';
import { DateAdapter } from '@angular/material/core';
import { Subject, takeUntil } from 'rxjs';
import { EtsDetailService } from '../ets-detail-service/ets-detail.service';
import { EtsDetailData } from '../ets-detail-service/ets-detail-data';
import { environment } from '../../environments/environment';
import { BaseComponent } from '../base-component/base-component';


@Component({
  selector: 'app-ets-calendar',
  templateUrl: './ets-calendar.component.html',
  styleUrls: ['./ets-calendar.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    MatDatepickerModule,
  ],

})

export class EtsCalendarComponent extends BaseComponent implements OnInit {
  @Input() identifier: string;
  @Input() embeddedIn: string;
  @ViewChild(MatCalendar) calendar: MatCalendar<Date>;
  calendarHeader = EtsCalendarHeader;

  details: EtsDetailData;
  eventDescription: string;
  firstEventIdentifier: string = '';
  globalConfig: any;
  initialLoad: boolean = true;
  activePhoto: number;
  videoLink: string;
  photoUrl: string;
  externalContent: boolean;
  loaded: boolean;
  title: string;
  selectedDate: string;

  @HostListener('click', ['$event.target.offsetParent']) clicked($event) {

    if (!$event || $event.id === undefined || $event.id === null) {
      return
    }
    if ($event.id === 'calendar_next' || $event.id === 'calendar_previous') {
      this.selectedEvents = [];
      this.mutipleEvents = false;
      this.loadEvents(this.calendar.activeDate);
    }
  }


  /**
   * Events received from API
   */
  events: EtsListingData;


  /**
   * Highlighted events specified in ETS Backend.
   */
  highlightEvents: EtsListingData;

  /**
   * Indicator for multiple events on selected date
   */
  mutipleEvents: boolean;

  /**
   * List of events shown in event list
   */
  selectedEvents: any


  /**
   * Array of events ordered by Year|Month|Day
   */
  availableEvents: any = [] ;

  todaysDate: Date;

  constructor(public listingService: EtsListingService,
    public configService: EtsConfigService,
    public routerService: EtsComponentRouterService,
    public datepipe: DatePipe,
    public spinnerService: NgxSpinnerService,
    public detailService: EtsDetailService,)
  {
    super();
  }

  ngOnInit(): void
  {
    this.initialize();
  }

  /**
   * Initialize required vars
   *
   * @returns void
   */
  private initialize(): void
  {
    this.initialLoad = true;
    this.configService.getShopIdentifierSubject().subscribe(
      () => {
        this.globalConfig = this.configService.getConfigObject();
        this.availableEvents = [];
        this.mutipleEvents = false;

        // * Get all specific EventIds from Backend
        if (this.globalConfig.calendar.highlightEvents !== '') {
          this.loadHighlightEvents();
        }

        this.loadEvents();
      }
    )
  }

  /**
   * Get Events from api
   *
   * @returns void
   */
  public loadEvents(startDate: Date = new Date()): void
  {
    // Stop if startDate is in the past.
    if (startDate.getFullYear() < new Date().getFullYear()) {
      return;
    }

    let month = this.datepipe.transform(startDate, 'MM');
    let year = this.datepipe.transform(startDate, 'yyyy');

    // If data for selected year/month allready exists. No API call is needed
    if(this.availableEvents.hasOwnProperty(year) && this.availableEvents[year].hasOwnProperty(month)) {
      return;
    }

    this.spinnerService.show('calendarSpinner');
    let selectedMonth = this.datepipe.transform(startDate, 'yyyy-MM');

    if (this.initialLoad && this.globalConfig.calendar.calendarStartMonth === 'MONTH_OF_FIRST_EVENT') {
      selectedMonth = null;
    }

    this.configService.getShopIdentifierSubject().subscribe(identifier => {
      this.listingService.loadListingData(identifier,2000, null, selectedMonth, true).subscribe( (data: EtsListingData) => {

        this.events = data;
        for (let i = 0; i < this.events.events.length; ++i) {
          if (this.events.events[i].name.toString().match('GUTSCHEIN')) {
            continue;
          }
          if(this.initialLoad) {

            if (this.globalConfig.calendar.calendarStartMonth === 'MONTH_OF_FIRST_EVENT') {
              this.calendar.activeDate = new Date(this.datepipe.transform(this.events.events[0].date, 'yyyy-MM'));
            }

            //* Load eventinformations of the first event if no custom text is set in partnershop backend
            if (!this.globalConfig.calendar.customDescription) {
              this.firstEventIdentifier = this.events.events[i].dateId.toString();

              this.loadEventInformations();
              this.detectLanguage();
              this.initialLoad = false;
            } else {
              this.loadCustomHeadline();
              this.initialLoad = false;
            }
            this.checkForCookies();
          }

          let currentMonth = this.datepipe.transform( new Date(this.events.events[i].date.replace(' ', 'T')), 'MM');
          let currentDay = this.datepipe.transform( new Date(this.events.events[i].date.replace(' ', 'T')), 'dd');
          let currentYear = this.datepipe.transform( new Date(this.events.events[i].date.replace(' ', 'T')), 'yyyy');

          if(!this.availableEvents.hasOwnProperty(currentYear)) {
            this.availableEvents[currentYear] = new Array();
          }

          if (!this.availableEvents[currentYear].hasOwnProperty(currentMonth)) {
            this.availableEvents[currentYear][currentMonth] = new Array();
          }

          if (!this.availableEvents[currentYear][currentMonth].hasOwnProperty(currentDay)) {
            this.availableEvents[currentYear][currentMonth][currentDay] = new Array();
          }

          // * prevents calender on showing events multiple times
          if (this.availableEvents[currentYear][currentMonth][currentDay].length > 0) {
            let exists = false;

            for(let n = 0; n < this.availableEvents[currentYear][currentMonth][currentDay].length; ++n) {
              let avEvent = this.availableEvents[currentYear][currentMonth][currentDay][n];

              if(avEvent.dateId.toString() === this.events.events[i].dateId.toString()) {
                exists = true;
              }
            }
            if (!exists) {
              if (!this.events.events[i].name.toString().match('GUTSCHEIN')) {
                this.availableEvents[currentYear][currentMonth][currentDay].push(this.events.events[i]);
              }
            }
          } else {
            if (!this.events.events[i].name.toString().match('GUTSCHEIN')) {
              this.availableEvents[currentYear][currentMonth][currentDay].push(this.events.events[i]);
            }
          }
        }
        this.toggleDateLabel();
        this.spinnerService.hide('calendarSpinner');
        this.loaded = true;
        this.calendar.updateTodaysDate();
      });
    });
  }


  /**
   * Loads headline informations set in the partnershop backend
   */
  private loadCustomHeadline(): void
  {
    this.title = this.globalConfig.calendar.title;
    this.videoLink = this.globalConfig.calendar.videoUrl;
    this.photoUrl  = environment.ETS_MEDIA_URL + this.configService.getShopIdentifier() + '/' + this.globalConfig.calendar.imageUrl;
    this.eventDescription = this.globalConfig.calendar.infoText;

  }

  dateFilter = (cellDate: Date): boolean => {

    let year = this.datepipe.transform(cellDate, 'yyyy');
    let month = this.datepipe.transform(cellDate, 'MM');
    let day = this.datepipe.transform(cellDate, 'dd');

    if (this.availableEvents.hasOwnProperty(year)) {
      if (this.availableEvents[year].hasOwnProperty(month)) {

        if (this.availableEvents[year][month].hasOwnProperty(day)) {
          return true;
        }
      }
    }

    return false;
  }

  dateClass: MatCalendarCellClassFunction<Date> = (cellDate: Date): string => {
    let year = this.datepipe.transform(cellDate, 'yyyy');
    let month = this.datepipe.transform(cellDate, 'MM');
    let day = this.datepipe.transform(cellDate, 'dd');

    if (this.availableEvents.hasOwnProperty(year)) {
      if (this.availableEvents[year].hasOwnProperty(month)) {
        let available = false;
        if(this.availableEvents[year][month].hasOwnProperty(day)) {
          for(let event of this.availableEvents[year][month][day]) {
            if (!event.notBookable) {
              available = true;
            }
            if (event.name.toString().match('GUTSCHEIN')) {
              available = false;
            }
          }

          if (available) {
            return 'calendar-event-available';
          } else {
            return 'calendar-event-notavailable';
          }

        }
      }
    }

    return '';
  }

  /**
   * Show list of events or redirects to event on selected date
   *
   * @param date string
   * @returns void
   */
  public selectDate(date: string): void
  {
    let year = this.datepipe.transform(new Date(date), 'yyyy');
    let month  = this.datepipe.transform(new Date(date), 'MM');
    let day = this.datepipe.transform(new Date(date), 'dd');
    this.selectedDate = day + '.' + month + '.' + year;
    let events = [];
    let lastTickets = [];
    if (this.availableEvents.hasOwnProperty(year)) {
      if (this.availableEvents[year].hasOwnProperty(month)) {
        if (this.availableEvents[year][month].hasOwnProperty(day)) {
          for (let event of this.availableEvents[year][month][day]) {
            // * Add only bookable events.
            if (event.notBookable === 0) {
              // * Filter events with 'tagesflexticket' in title and put them last in list.
              if (event.name.includes('Tagesflexticket')) {
                lastTickets.push(event);
              } else {
                events.push(event);
              }
            }
          }

          events.push(...lastTickets);
        }

        if (events.length === 1) {
          this.selectedEvents = null;
          this.mutipleEvents = false;
          if (this.globalConfig.calendar.directBooking) {
            this.routerService.navigateEmbed([{name: this.embedName, target: 'calticket-' + events[0].dateId}]);
          } else {
            this.routerService.navigateEmbed([{name: this.embedName, target: 'detail-' + events[0].dateId}]);
          }
        } else {
          this.calendar.activeDate = new Date(date);
          this.mutipleEvents = true;
          this.selectedEvents = events;

          if (window.innerWidth <= 992) {
            if (document.querySelector('.eventList .headline.date')) {
              document.querySelector('.eventList .headline.date').scrollIntoView({behavior: 'smooth', block: 'start'});
            } else {
              // * Timeout is required for initialisation
              setTimeout(() => {
                document.querySelector('.eventList .headline.date').scrollIntoView({behavior: 'smooth', block: 'start'});
              }, 100);
            }
          }
        }
      }
    }
  }

  /**
   * Loads event related data
   *
   * @returns void
   */
  public loadEventInformations(): void {
    this.loaded = false;
    this.spinnerService.show('calendarSpinner');

    this.detailService.loadDetailData(this.firstEventIdentifier).subscribe(
      details => {
        this.details = null;
        if (details !== null) {
          this.details = details;

          this.title = details?.headliner?.infoTitle;

          this.loaded = true;
          this.activePhoto = 0;
          this.detectLanguage();
          if (details.headliner.eventVideo !== '') {
            const videoFrame = details.headliner.eventVideo.replace(/\\"/g, '"');
            const video = new DOMParser().parseFromString(videoFrame, 'text/html');
            this.videoLink = video.body.getElementsByTagName('iframe')[0].src;
          }
          this.spinnerService.hide('calendarSpinner');
        }
      },
      error => {
        console.log('ETS Calendar - LoadEventInformations', error);
      });
  }


  public loadHighlightEvents(): void
  {
    let events = this.globalConfig.calendar.highlightEvents.split(',').join(':');

    this.listingService.loadListingData(this.identifier,20,null,null,false,events).subscribe( (listing: EtsListingData) => {
      this.highlightEvents = listing;

    },
    (error: Error) => {
      console.log('ETS Calendarwidget:', error);
    });
  }

  /**
   * Checks if cookie exists
   *
   * @returns void
   */
  private checkForCookies(): void {
    this.configService.getShopIdentifierSubject().subscribe((identifier: string) => {
      const cookie = this.configService.getCookieByName('ets-cookie-preferences-' + this.configService.getShopIdentifier()).split('|');

      for (const val of cookie) {
        if (val.split(':')[0] === 'external') {
          if (val.split(':')[1] === '1') {
            this.externalContent = true;
          }
        }
      }

      this.globalConfig = this.configService.getConfigObject();

      const urlParams = new URLSearchParams(
        window.location.search
      );
      if (urlParams.has('edit')) {
        setTimeout(() => {
          this.scrollToCalendar();
        }, 1000);
      }
    });
  }

  /**
   * Detects which languages is used
   *
   * @returns void
   */
  private detectLanguage(): void {
    if (this.globalConfig.misc.languageCode === 'en') {
      if (this.details?.headliner?.shortDescriptionEN !== undefined && this.details?.headliner?.shortDescriptionEN !== null
        && this.details?.headliner?.shortDescriptionEN !== '') {
        this.eventDescription = this.details?.headliner?.shortDescriptionEN;
      } else {
          this.eventDescription = this.details?.headliner?.shortDescription;
        }
    } else {
      this.eventDescription = this.details?.headliner?.shortDescription;
    }
  }

  /**
   * Scrolls page to ticketing widget
   *
   * @returns void
   */
  public scrollToCalendar(): void {
    var ticketBox = document.querySelector('.calendar');
    var headerOffset = 190;
    var elementPosition = ticketBox.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  }


  /**
   *
   */
  public toggleDateLabel(): void
  {
    setTimeout(() => {
      let month  = new Date(this.calendar.activeDate.toString());
      let firstDayOfMonth = new Date(month.getFullYear(), month.getMonth(), 1);
      let weekday = firstDayOfMonth.toLocaleString('en-EN', {weekday: 'long'});
      let element = (document.querySelector('.mat-calendar-body-label') as HTMLElement);
      if (element) {
        if (weekday.toLowerCase() === 'monday' || weekday.toLowerCase() === 'tuesday') {
          element.style.display = 'none';
        } else {
          element.style.display = 'revert';
          element.innerHTML = '';
        }
      }
    });
  }
}

/** Custom header component for datepicker. */
@Component({
  selector: 'ets-calendar-header',
  styleUrls: ['./ets-calendar.component.css'],
  template: `
    <div class="example-header">
      <button id="calendar_previous" mat-icon-button (click)="previousClicked('month')">

        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
</svg>

      </button>
      <span class="example-header-label">{{periodLabel}}</span>
      <button id="calendar_next" mat-icon-button (click)="nextClicked('month')">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
</svg>

      </button>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class EtsCalendarHeader<D> implements OnDestroy {
  private _destroyed = new Subject<void>();

  @Input() embedName: string;
  @Input() identifier: string;

  /**
   * Events received from API
   */
  events: EtsListingData;

  /**
   * Indicator for multiple events on selected date
   */
  mutipleEvents: boolean;

  /**
   * List of events shown in event list
   */
  selectedEvents: any

  first: boolean = true;

  /**
   * Array of events ordered by Year|Month|Day
   */
  availableEvents: any = [] ;

  constructor(
    public datepipe: DatePipe,
    public _calendar: MatCalendar<D>,
    public _dateAdapter: DateAdapter<D>,
    public cdr: ChangeDetectorRef
  ) {
    _calendar.stateChanges.pipe(takeUntil(this._destroyed)).subscribe(() => {

      cdr.markForCheck();
      this.toggleDateLabel(this.first);
      this.first = false;
    });
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  get periodLabel() {
    return this.datepipe.transform(this._calendar.activeDate.toString(), 'MMMM yyyy');
  }

  public previousClicked(mode: 'month' | 'year') {
    this.first = true;
    this._calendar.activeDate =
      mode === 'month'
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1)
        : this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1);
  }

  public nextClicked(mode: 'month' | 'year') {
    this.first = true;
    this._calendar.activeDate =
      mode === 'month'
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1)
        : this._dateAdapter.addCalendarYears(this._calendar.activeDate, 1);
  }

  /**
   *
   */
  public toggleDateLabel(first: boolean): void
  {
    setTimeout(() => {
      let month  = new Date(this._calendar.activeDate.toString());
      let firstDayOfMonth = new Date(month.getFullYear(), month.getMonth(), 1);
      let weekday = firstDayOfMonth.toLocaleString('en-EN', {weekday: 'long'});
      let element = (document.querySelector('.mat-calendar-body-label') as HTMLElement);
      if (element) {
        if (weekday.toLowerCase() === 'monday' || weekday.toLowerCase() === 'tuesday') {
          element.style.display = 'none';
        } else {
          element.style.display = 'revert';
          element.innerHTML = '';
        }
      }

      // * BC mat-calender always selects a date as active after changing month. Remove active class from element.
      if (first) {
        document.querySelector('.mat-calendar-body-active')?.classList.remove('mat-calendar-body-active');
      }
    });
  }
}
