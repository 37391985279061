import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {EtsConfigService} from '../ets-config-service/ets-config.service';
import {
  EtsAGB, EtsDatenschutz,
  EtsFormDataRegister, EtsImpressum,
  EtsPayment,
  EtsPaymentData, EtsPersonalisation,
  EtsShipping,
  EtsShippingData,
  EtsTelecashData, EtsUeberUns
} from './ets-checkout-data';
import {delay, map, retryWhen, take, tap} from 'rxjs/operators';
import {EtsLoginService} from '../ets-login-service/ets-login-service';
import {ToastrService} from 'ngx-toastr';
import {EtsBasketService} from '../ets-basket-service/ets-basket-service';
import { TranslateService } from '@ngx-translate/core';
import { ErrorcodeService } from '../helpers/errorcode-service.service';
import { LogService } from '../services/LogService.service';

@Injectable({
  providedIn: 'root'
})
export class EtsCheckoutService {
  private checkoutSubject: BehaviorSubject<EtsTelecashData>;
  private checkoutLoading: BehaviorSubject<boolean>;

  private formDataRegisterLoading: BehaviorSubject<boolean>;
  private formDataRegisterSubject: BehaviorSubject<EtsFormDataRegister>;

  private shippingDataLoading: BehaviorSubject<boolean>;
  private shippingDataSubject: BehaviorSubject<EtsShippingData>;
  private shippingSubject: BehaviorSubject<EtsShipping>;

  private paymentDataLoading: BehaviorSubject<boolean>;
  private paymentDataSubject: BehaviorSubject<EtsPaymentData>;
  private paymentSubject: BehaviorSubject<EtsPayment>;

  private paymentSuccessTest: BehaviorSubject<boolean>;
  private paymentSuccessLoading: BehaviorSubject<boolean>;

  private personalisationSubject: BehaviorSubject<EtsPersonalisation[]>;

  private agbSubject: BehaviorSubject<EtsAGB[]>;
  private impressumSubject: BehaviorSubject<EtsImpressum>;
  private datenschutzSubject: BehaviorSubject<EtsDatenschutz>;
  private ueberUnsSubject: BehaviorSubject<EtsUeberUns>;

  private bc: BroadcastChannel;

  public constructor(
    @Inject('ETS_API_URL') public apiUrl: string,
    private http: HttpClient,
    private config: EtsConfigService,
    private login: EtsLoginService,
    private toastr: ToastrService,
    private basketService: EtsBasketService,
    private errorMessageService: ErrorcodeService,
    public translate: TranslateService,
    private logService: LogService
  ) {
    const checkoutStart = new EtsTelecashData();
    this.checkoutSubject = new BehaviorSubject<EtsTelecashData>(checkoutStart);
    this.checkoutLoading = new BehaviorSubject<boolean>(false);

    const formDataRegisterStart = new EtsFormDataRegister();
    formDataRegisterStart.salutations = [];
    formDataRegisterStart.county = [];
    this.formDataRegisterSubject = new BehaviorSubject<EtsFormDataRegister>(formDataRegisterStart);
    this.formDataRegisterLoading = new BehaviorSubject<boolean>(false);

    const shippingMethodStart = new EtsShippingData();
    shippingMethodStart.shippingMethods = [];
    shippingMethodStart.personalisation = false;
    this.shippingDataSubject = new BehaviorSubject<EtsShippingData>(shippingMethodStart);
    this.shippingDataLoading = new BehaviorSubject<boolean>(false);
    const shippingStart = new EtsShipping();
    this.shippingSubject = new BehaviorSubject<EtsShipping>(shippingStart);

    const paymentMethodStart = new EtsPaymentData();
    paymentMethodStart.paymentMethods = [];
    this.paymentDataSubject = new BehaviorSubject<EtsPaymentData>(paymentMethodStart);
    this.paymentDataLoading = new BehaviorSubject<boolean>(false);
    const paymentStart = new EtsPayment();
    this.paymentSubject = new BehaviorSubject<EtsPayment>(paymentStart);

    this.paymentSuccessTest = new BehaviorSubject<boolean>(false);
    this.paymentSuccessLoading = new BehaviorSubject<boolean>(true);

    this.personalisationSubject = new BehaviorSubject<EtsPersonalisation[]>([]);
    this.agbSubject = new BehaviorSubject<EtsAGB[]>([]);

    const impressum = new EtsImpressum();
    impressum.title = '';
    impressum.content = '';
    this.impressumSubject = new BehaviorSubject<EtsImpressum>(impressum);

    const datenschutz = new EtsDatenschutz();
    datenschutz.title = '';
    datenschutz.content = '';
    this.datenschutzSubject = new BehaviorSubject<EtsDatenschutz>(datenschutz);

    const ueberUns = new EtsUeberUns();
    ueberUns.title = '';
    ueberUns.content = '';
    this.ueberUnsSubject = new BehaviorSubject<EtsUeberUns>(ueberUns);

    this.config.getShopIdentifierSubject().subscribe(identifier => {
      if (identifier !== null && identifier !== '') {
        this.bc = new BroadcastChannel('ets_checkout_channel-' + identifier);
        this.bc.onmessage = (ev) => {
          if (ev.data === 'removeAll') {
            this.checkoutSubject = new BehaviorSubject<EtsTelecashData>(checkoutStart);
            this.formDataRegisterSubject = new BehaviorSubject<EtsFormDataRegister>(formDataRegisterStart);
            this.shippingDataSubject = new BehaviorSubject<EtsShippingData>(shippingMethodStart);
            this.shippingSubject = new BehaviorSubject<EtsShipping>(shippingStart);
            this.paymentDataSubject = new BehaviorSubject<EtsPaymentData>(paymentMethodStart);
            this.paymentSubject = new BehaviorSubject<EtsPayment>(paymentStart);
            this.personalisationSubject = new BehaviorSubject<EtsPersonalisation[]>([]);
          } else if (ev.data === 'updateShipping') {
            this.reloadShippingMethod();
          } else if (ev.data === 'updatePayment') {
            this.reloadPaymentMethod();
          } else if (ev.data === 'updatePersonalisation') {
            const token = localStorage.getItem('ets-personalisation-' + this.config.getShopIdentifier());
            this.shippingSubject.next(JSON.parse(token));
          } else if (ev.data === 'updatePayment') {
            const token = localStorage.getItem('ets-shipping-' + this.config.getShopIdentifier());
            this.shippingSubject.next(JSON.parse(token));
          } else if (ev.data === 'updatePayment') {
            const token = localStorage.getItem('ets-shipping-' + this.config.getShopIdentifier());
            this.shippingSubject.next(JSON.parse(token));
          } else if (ev.data === 'updateBasket') {
            this.basketService.updateBasket().pipe(take(1)).subscribe();
          }
        };
      }
    });
  }

  public get getFormDataRegisterLoadingValue(): boolean {
    return this.formDataRegisterLoading.value;
  }

  public get getFormDataRegisterSubjectValue(): EtsFormDataRegister {
    return this.formDataRegisterSubject.value;
  }

  public get getShippingDataLoadingValue(): boolean {
    return this.shippingDataLoading.value;
  }

  public get getShippingDataSubjectValue(): EtsShippingData {
    return this.shippingDataSubject.value;
  }

  public get getPaymentDataLoadingValue(): boolean {
    return this.paymentDataLoading.value;
  }

  public get getPaymentDataSubjectValue(): EtsPaymentData {
    return this.paymentDataSubject.value;
  }

  public get getPaymentSubjectValue(): EtsPayment {
    return this.paymentSubject.value;
  }

  public get getShippingSubjectValue(): EtsShipping {
    return this.shippingSubject.value;
  }

  public get getCheckoutSubjectValue(): EtsTelecashData {
    return this.checkoutSubject.value;
  }

  public get getPaymentSuccessLoadingValue(): boolean {
    return this.paymentSuccessLoading.value;
  }

  public get getPaymentSuccessTestValue(): boolean {
    return this.paymentSuccessTest.value;
  }

  public get getPaymentSuccessTest(): BehaviorSubject<boolean> {
    return this.paymentSuccessTest;
  }

  public get getPersonalisationSubjectValue(): EtsPersonalisation[] {
    return this.personalisationSubject.value;
  }

  public get getAGBSubjectValue(): EtsAGB[] {
    return this.agbSubject.value;
  }

  /**
   * Recieves informations from Telecash and redirects customer to checkout finish page
   *
   * @returns Observable<EtsTelecashData>
   */
  public getTelecashData(): Observable<EtsTelecashData> {
    let paymentMethod;
    if (localStorage.getItem('ets-payment-' + this.config.getShopIdentifier())) {
      paymentMethod = JSON.parse(localStorage.getItem('ets-payment-' + this.config.getShopIdentifier()));
    }
    if (this.getPaymentSubjectValue.id === 'sepa' || this.getPaymentSubjectValue.id === 'cash' || this.getPaymentSubjectValue.id === 'on-account'
      || this.basketService.basketSubjectValue.totalPrice === 0
      || paymentMethod.id === 'sepa' || paymentMethod.id === 'cash' || paymentMethod.id === 'on-account') {
      const telecashfalse = new EtsTelecashData();
      telecashfalse.url = 'false';
      this.checkoutSubject.next(telecashfalse);
      return of(telecashfalse);
    }
    this.checkoutLoading.next(true);
    const headers = this.getHttpHeaders();

    const urlParams = new URLSearchParams(
      window.location.search
    );

    let telecashResponseUrl = '';
    let queryParameters = [];
    for (const [key, value] of urlParams) {
      console.log(`${key} : ${value}`);
      // * Get last urlParam with widget identifier.
      if (key.startsWith('ets')) {
        telecashResponseUrl = key;
      } else {
        // * Collect other query parameters and append them to the TCResponse urls later.
        // * Required for widget partnershops, as some widget integrations use query parameters to determine which widget should be displayed.
        queryParameters.push(key + '=' + value);
      }
    }

    const formData: any = new URLSearchParams();
    formData.set('responseSuccessURL', location.href.substring(0, location.href.lastIndexOf('?')) + '?' + telecashResponseUrl + '=checkout-finish' + (queryParameters.length >= 1  ? '&' + queryParameters.join('&') : ''));
    formData.set('responseFailURL', location.href.substring(0, location.href.lastIndexOf('?')) + '?' + telecashResponseUrl + '=checkout-failure' + (queryParameters.length >= 1  ? '&' + queryParameters.join('&') : ''));

    return this.http.get(this.apiUrl + 'payment/telecash?' + formData.toString(), { headers }).pipe(
      take(1),
      map((data: any) => {
        const telecash = new EtsTelecashData();
        if (data && data.status && data.status !== 'success') {
          this.checkoutLoading.next(false);
        }
        if (data && data.data && data.data.post_data) {
          telecash.postData = data.data.post_data;
          telecash.url = data.data.url;
          this.checkoutLoading.next(true);
        }
        this.checkoutSubject.next(telecash);
        return telecash;
      })
    );
  }

  /**
   * Fills register form with predefined salutations and countries
   *
   * @returns Observable<EtsFormDataRegister>
   */
  public loadFormDataRegister(): Observable<EtsFormDataRegister> {
    this.formDataRegisterLoading.next(true);
    const headers = this.getHttpHeaders();
    return this.http.get(this.apiUrl + 'formdata/customer-register', { headers }).pipe(
      take(1),
      map((data: any) => {
        const etsFormRegisterData = new EtsFormDataRegister();
        etsFormRegisterData.salutations = [];
        etsFormRegisterData.county = [];
        if (data && data.data && data.data.fields) {
          if (data.data.fields.findIndex(x => x.name === 'salutation') >= 0) {
            const k = data.data.fields.findIndex(x => x.name === 'salutation');
            for (const key in data.data.fields[k].options) {
              if (data.data.fields[k].options.hasOwnProperty(key)) {
                etsFormRegisterData.salutations.push({
                  key: parseInt(key, 10),
                  value: data.data.fields[k].options[key],
                });
              }
            }
          }
          if (data.data.fields.findIndex(x => x.name === 'country') >= 0) {
            const k = data.data.fields.findIndex(x => x.name === 'country');
            for (const key in data.data.fields[k].options) {
              if (data.data.fields[k].options.hasOwnProperty(key)) {
                etsFormRegisterData.county.push({
                  key: parseInt(key, 10),
                  value: data.data.fields[k].options[key],
                });
              }
            }
          }
        }
        this.formDataRegisterSubject.next(etsFormRegisterData);
        return this.formDataRegisterSubject.value;
      }),
      tap(() => this.formDataRegisterLoading.next(false))
    );
  }

  /**
   * Gets all for this basket available shippingmethods
   *
   * @returns Observable<EtsShippingData>
   */
  public loadShippingMethods(): Observable<EtsShippingData> {
    this.shippingDataLoading.next(true);

    const headers = this.getHttpHeaders();
    return this.http.get(this.apiUrl + 'order/shipping-methods', { headers }).pipe(
      take(1),
      map((data: any) => {
        const shippingMethods = new EtsShippingData();
        shippingMethods.shippingMethods = [];
        if (data && data.data) {
          shippingMethods.shippingMethods = data.data;
        }
        shippingMethods.personalisation = !!data.personalization_required;

        this.shippingDataSubject.next(shippingMethods);
        return shippingMethods;
      }),
      tap(() => {
        this.shippingDataLoading.next(false);
      })
    );
  }

  /**
   * Sets shippingmethod to order
   *
   * @param shippingMethodId number
   * @returns Observable<boolean>
   */
  setShippingMethod(shippingMethodId: number): Observable<boolean> {
    let headers = this.getHttpHeaders();
    headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
    this.shippingDataLoading.next(true);

    const formData: any = new URLSearchParams();
    formData.set('shippingMethod', shippingMethodId);
    return this.http.post(this.apiUrl + 'order/shipping-methods', formData.toString(), { headers }).pipe(
      take(1),
      map((data: any) => {
        this.getShippingDataSubjectValue.personalisation = data.personalization_required === true ? true : false;

        return !(data && data.status && data.status !== 'success');
      },
      error => {
        console.log('Error SetShippingMethod',error);
        this.toastr.error(this.errorMessageService.getErrorMessage('shippingmethod', error.error.errorCode));
        this.shippingDataLoading.next(false);
      }),
      tap(() => {
        const p = this.getShippingDataSubjectValue.shippingMethods.findIndex(x => x.id === shippingMethodId);
        this.shippingSubject.next(this.getShippingDataSubjectValue.shippingMethods[p]);
        localStorage.setItem('ets-shipping-' + this.config.getShopIdentifier(), JSON.stringify(this.getShippingDataSubjectValue.shippingMethods[p]));
        this.bc.postMessage('updateShipping');
        this.shippingDataLoading.next(false);
      })
    );
  }

  checkForShippingMethod(): Observable<boolean> {
    let headers = this.getHttpHeaders();
    headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');

    return this.http.get(this.apiUrl + 'order/check-shipping-method', {headers}).pipe(
      take(1),
      map((data: any) => {
        return (data && data.status && data.status === 'success');
      })
    );
  }

  /**
   * Gets all for this basket available paymentmethods
   *
   * @returns Observable<EtsPaymentData>
   */
  loadPaymentMethod(): Observable<EtsPaymentData> {
    this.paymentDataLoading.next(true);
    const headers = this.getHttpHeaders();
    return this.http.get(this.apiUrl + 'order/payment-methods', { headers }).pipe(
      take(1),
      map((data: any) => {
        const methods = new EtsPaymentData();
        methods.paymentMethods = [];
        if (data && data.data) {
          for (const method in data.data) {
            if (data.data.hasOwnProperty(method)) {
              methods.paymentMethods.push({
                id: data.data[method].id,
                name: data.data[method].name,
                info: data.data[method].info,
                type: data.data[method].type,
                gateway: data.data[method].gateway ? data.data[method].gateway : null,
                gatewayId: data.data[method].gateway_id ? data.data[method].gateway_id : null,
                gatewayAuth: data.data[method].gateway_auth ? data.data[method].gateway_auth : null,
                paymentId: data.data[method].paymentId,
                mode: data.data[method].mode,
                imageUrl: data.data[method].imageUrl,
              });
            }
          }
        }
        this.paymentDataSubject.next(methods);
        return methods;
      }),
      tap(() => {
        this.paymentDataLoading.next(false);
      })
    );
  }

  /**
   * Gets informations for personalization form if event has ticket personalization activated
   *
   * @returns Observable<EtsPersonalisation>
   */
  loadPersonalisationData(): Observable<EtsPersonalisation[]> {
    const headers = this.getHttpHeaders();
    return this.http.get(this.apiUrl + 'order/personalized-tickets', { headers }).pipe(
      take(1),
      map((data: any) => {
        const values = [];

        if (data && data.data && data.data.values) {
          for (const ticket of data.data.values) {
            values.push({
              titleOfEvent: ticket.titleOfEvent,
              visitorNumber: ticket.visitorNumber,
              data: {
                eventNos: ticket.data.eventNos,
                seatNos: ticket.data.seatNos,
                firstnames: ticket.data.firstnames,
                lastnames: ticket.data.lastnames,
                birthdays: ticket.data.birthdays,
              }
            });
          }
        }
        return values;
      }),
    );
  }

  /**
   * Set personalization informations to event
   *
   * @param etsPersonalisation EtsPersonalisation[]
   * @returns Observable<boolean>
   */
  setPersonalisationData(etsPersonalisation: EtsPersonalisation[]): Observable<boolean> {
    let headers = this.getHttpHeaders();
    headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');

    const formData: any = new URLSearchParams();
    for (const values of etsPersonalisation) {
      formData.append('firstnames[]', values.data.firstnames);
      formData.append('lastnames[]', values.data.lastnames);
      formData.append('streetName[]', values.data.streetname);
      formData.append('streetNumber[]', values.data.streetnumber);
      formData.append('zip[]', values.data.zip);
      formData.append('city[]', values.data.city);
      formData.append('birthdays[]', values.data.birthdays);
      formData.append('eventNos[]', values.data.eventNos);
      formData.append('seatNos[]', values.data.seatNos);
    }
    return this.http.post(this.apiUrl + 'order/personalized-tickets', formData.toString(), { headers }).pipe(
      take(1),
      map((data: any) => {
        return !(data && data.status && data.status !== 'success');
      }),
      tap(() => {
        this.personalisationSubject.next(etsPersonalisation);
        localStorage.setItem('ets-personalisation-' + this.config.getShopIdentifier(), JSON.stringify(etsPersonalisation));
        this.bc.postMessage('updatePersonalisation');
      }),
    );
  }

  /**
   * Sets payment method to basket
   *
   * @param paymentMethodId number
   * @param iban string = null
   * @param bic string = null
   * @param couponCode string = null
   * @returns Observable<boolean>
   */
  setPaymentMethod(paymentMethodId: string, iban: string = null, bic: string = null, couponCode: string = null): Observable<any> {
    let headers = this.getHttpHeaders();
    headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
    this.paymentDataLoading.next(true);

    const formData: any = new URLSearchParams();
    formData.set('paymentTypeId', paymentMethodId);
    if (iban !== null) {
      formData.set('iban', iban.trim());
    }
    if (bic !== null) {
      formData.set('bic', bic);
    }
    if (couponCode !== null) {
      formData.set('couponCode', couponCode);
    }
    return this.http.post(this.apiUrl + 'order/payment-methods', formData.toString(), { headers }).pipe(
      take(1),
      map((data: any) => {
        return data;
        // return !(data && data.status && data.status !== 'success');
      }),
      tap(() => {
        const p = this.getPaymentDataSubjectValue.paymentMethods.findIndex(x => x.id === paymentMethodId);
        this.paymentSubject.next(this.getPaymentDataSubjectValue.paymentMethods[p]);
        localStorage.setItem('ets-payment-' + this.config.getShopIdentifier(), JSON.stringify(this.getPaymentDataSubjectValue.paymentMethods[p]));
        this.paymentDataLoading.next(false);
        this.bc.postMessage('updatePayment');
        this.basketService.updateBasket();
      })
    );
  }

  /**
   * Checks if payment was successfull
   *
   * @returns Observable<boolean>
   */
  checkPaymentSuccessful(): Observable<boolean> {
    this.paymentSuccessLoading.next(true);
    const headers = this.getHttpHeaders();

    let meetandgreet = '';

    switch(this.config.getShopIdentifier()) {
      case '8284e02215089cb7d8cf019e5697c390':
      case '53a8edde17f2780485ccbdf81eb74e64':
      case 'f182d9ad06e9bda677d7a3046a0dc7ab':
      case '5306f1fd3425bbd1907c34d28fa82da3':
      case 'e26ba42994bef547cee9e624bb48dfe6':
        if (sessionStorage.getItem('ets-' + this.config.getShopIdentifier() + '-meetandgreet')) {
          meetandgreet = sessionStorage.getItem('ets-' + this.config.getShopIdentifier() + '-meetandgreet');
        }
        break;
    }

    return this.http.get(this.apiUrl + 'order/info' + (meetandgreet !== '' ? '?meetAndGreetConsent=1' : '') , { headers }).pipe(
      map((data: any) => {
        if (data && data.data && data.data.completeStatusOfOrder && data.data.completeStatusOfOrder === 'J') {
          this.paymentSuccessLoading.next(false);
          this.paymentSuccessTest.next(true);
          return true;
        } else {
          // Check if OrderError exists. If thats so the order could not be canceled automatically.
          if (data && data.data && data.data.OrderError !== '') {
            this.paymentSuccessLoading.next(false);
            this.paymentSuccessTest.next(false);
            this.toastr.error(data.data.OrderError, 'Bestellung fehlgeschlagen', {disableTimeOut: true});
            return false;
          }
        }

        this.paymentSuccessTest.next(false);
        throw new Error('Waiting for Order');
      }),
      retryWhen(errors => errors.pipe( delay(2000),tap((error: HttpErrorResponse) => {
        console.log('Check Payment Error', error);
        // * Stop retries on specific http codes
        if (error.status === 404 ||  error.status === 401) {
          throw error;
        }
      } ), take(10)))
    );
  }

  /**
   * Get translated checkout error message.
   * @param error HttpErrorResponse
   * @returns string
   */
  public getCheckoutError(error: HttpErrorResponse): string {
    switch (error.status) {
      case 401: {
        return this.translate.instant('toastr.checkout.unauthorizedUser');
      }
      case 404: {
        if (error.error.errorCode === '9q6V2' || error.error.errorCode === 'Xe976') {

          return this.translate.instant('toastr.checkout.orderNotFound', {errorCode: error.error.errorCode}).toString();

        } else {
          // * Generic 404 Status code
          return this.translate.instant('toastr.checkout.payError').toString();
        }

      }
      default: {
        return this.translate.instant('toastr.checkout.generalError');
      }


    }
  }

  getPaymentData(): Observable<any>
  {
    const headers = this.getHttpHeaders();

    return this.http.get(this.apiUrl + 'order/info', {headers});
  }

  /**
   * Gets terms and conditions from ETS
   *
   * @returns Observable<EtsAGB[]>
   */
  getAgb(): Observable<EtsAGB[]> {
    const headers = this.getHttpHeaders();

    let infoPage = 'agb'

    if (this.config.getConfigObject().misc.languageCode == 'en') {
      infoPage = 'terms-and-conditions';
    }

    return this.http.get(this.apiUrl + 'info/' + infoPage, { headers }).pipe(
      take(1),
      map((data: any) => {
        if (data && data.data && data.data.title && data.data.content) {
          const agb = [];
          agb.push({
            title: data.data.title,
            content: data.data.content,
          });

          this.agbSubject.next(agb);
          return agb;
        } else {
          return null;
        }
      })
    );
  }

  /**
   * Gets imprint from ETS
   *
   * @returns Observable<EtsImpressum>
   */
  getImpressum(): Observable<EtsImpressum> {
    const headers = this.getHttpHeaders();

    let infoPage = 'impressum';

    if (this.config.getConfigObject().misc.languageCode == 'en') {
      infoPage = 'legal-notice';
    }

    return this.http.get(this.apiUrl + 'info/' + infoPage, { headers }).pipe(
      take(1),
      map((data: any) => {
        if (data && data.data && data.data.title && data.data.content) {
          const impressum = new EtsImpressum();
          impressum.title =  data.data.title;
          impressum.content = data.data.content;

          this.impressumSubject.next(impressum);
          return impressum;
        } else {
          return null;
        }
      })
    );
  }

  /**
   * Gets privacy policy from ETS
   *
   * @returns Observable<EtsDatenschutz>
   */
  getDatenschutz(): Observable<EtsDatenschutz> {
    const headers = this.getHttpHeaders();
    let infoPage = 'datenschutz';

    if (this.config.getConfigObject().misc.languageCode == 'en') {
      infoPage = 'privacy-policy';
    }

    return this.http.get(this.apiUrl + 'info/' + infoPage, { headers }).pipe(
      take(1),
      map((data: any) => {
        if (data && data.data && data.data.title && data.data.content) {
          const datenschutz = new EtsDatenschutz();
          datenschutz.title =  data.data.title;
          datenschutz.content = data.data.content;

          this.datenschutzSubject.next(datenschutz);
          return datenschutz;
        } else {
          return null;
        }
      })
    );
  }

  /**
   * Gets about us from ETS
   *
   * @returns Observable<EtsUeberUns>
   */
  getUeberUns(): Observable<EtsUeberUns> {
    const headers = this.getHttpHeaders();

    return this.http.get(this.apiUrl + 'info/ueber-uns', { headers }).pipe(
      take(1),
      map((data: any) => {
        if (data && data.data && data.data.title && data.data.content) {
          const ueberUns = new EtsUeberUns();
          ueberUns.title =  data.data.title;
          ueberUns.content = data.data.content;

          this.ueberUnsSubject.next(ueberUns);
          return ueberUns;
        } else {
          return null;
        }
      })
    );
  }

  /**
   * Sends email with Passwort reset link to the given email
   *
   * @param email string
   * @param link string
   * @returns Observable<boolean>
   */
  triggerForgotPassword(email: string, link: string): Observable<boolean> {
    const headers = this.getHttpHeaders();

    return this.http.delete(this.apiUrl + 'login/password/reset/' + email + '?resetUrl=' + link, { headers }).pipe(
      take(1),
      map((data: any) => {
        return data && data.status && data.status === 'success';
      })
    );
  }

  setPaid(): Observable<boolean> {
    const headers = this.getHttpHeaders();

    return this.http.post(this.apiUrl + 'payment/status', { paid: true }, { headers }).pipe(
      take(1),
      map((data: any) => {
        return data && data.status && data.status === 'success';
      })
    );
  }
  /**
   * Sends password change request to api
   *
   * @param hash string
   * @param password string
   * @param passwordRepeat string
   * @returns Observable<boolean>
   */
  triggerForgotPasswordReset(hash: string, password: string, passwordRepeat: string): Observable<boolean> {
    let headers = this.getHttpHeaders();
    headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
    const formData: any = new URLSearchParams();
    formData.set('password', password);
    formData.set('passwordRepeat', passwordRepeat);

    return this.http.post(this.apiUrl + 'login/password/reset/' + hash, formData.toString(), { headers }).pipe(
      take(1),
      map((data: any) => {
        return data && data.status && data.status === 'success';
      })
    );
  }

  /**
   * Refresh given paymentmethods with paymentmethods stored in localStorage
   *
   * @returns void
   */
  reloadPaymentMethod(): void {
    if (localStorage.getItem('ets-payment-' + this.config.getShopIdentifier()) !== null
      && localStorage.getItem('ets-payment-' + this.config.getShopIdentifier()) !== undefined ) {
      this.paymentSubject.next(JSON.parse(localStorage.getItem('ets-payment-' + this.config.getShopIdentifier())));
    }
  }

  /**
   * Refresh given shippingmethods with shippingmethods stored in localStorage
   *
   * @returns void
   */
  reloadShippingMethod(): void {
    if (localStorage.getItem('ets-shipping-' + this.config.getShopIdentifier()) !== null) {
      this.shippingSubject.next(JSON.parse(localStorage.getItem('ets-shipping-' + this.config.getShopIdentifier())));
    }
  }

  /**
   * Deletes after successfull order keys in localstorage and sends request to delete api session
   *
   * @returns void
   */
  orderSuccessful(): void {
    if (localStorage.getItem('ets-basket-' + this.config.getShopIdentifier())) {
      let successfullOrderBasket = JSON.parse(localStorage.getItem('ets-basket-' + this.config.getShopIdentifier()));

      let eventIds = [];

      let items = [];

      for (let item of successfullOrderBasket.items) {
        eventIds.push(item.eventId);
        items.push({
          quantity: item?.quantity,
          title: item?.title,
          price: item?.price,
          eventId: item?.eventId,
          organizer: item?.organizer?.name
        });
      }

      const orderSuccessfullEvent = new CustomEvent("etsOrderSuccessfull", {
        detail: {
          totalprice: successfullOrderBasket.totalPrice,
          eventIds: eventIds,
          events: items
        },
        bubbles: false
      })

      document.dispatchEvent(orderSuccessfullEvent);
    }

    localStorage.removeItem('ets-shipping-' + this.config.getShopIdentifier());
    localStorage.removeItem('ets-payment-' + this.config.getShopIdentifier());
    localStorage.removeItem('ets-basket-' + this.config.getShopIdentifier());
    localStorage.removeItem('ets-shipping-' + this.config.getShopIdentifier());
    localStorage.removeItem('ets-personalisation-' + this.config.getShopIdentifier());
    localStorage.removeItem('ets-eventTime-' + this.config.getShopIdentifier());
    sessionStorage.removeItem('ets-' +this.config.getShopIdentifier() + '-meetandgreet');
    this.basketService.isCheckout = false;
    this.bc.postMessage('removeAll');

    const checkoutStart = new EtsTelecashData();
    this.checkoutSubject = new BehaviorSubject<EtsTelecashData>(checkoutStart);

    const formDataRegisterStart = new EtsFormDataRegister();
    formDataRegisterStart.salutations = [];
    formDataRegisterStart.county = [];
    this.formDataRegisterSubject = new BehaviorSubject<EtsFormDataRegister>(formDataRegisterStart);

    const shippingMethodStart = new EtsShippingData();
    shippingMethodStart.shippingMethods = [];
    shippingMethodStart.personalisation = false;
    this.shippingDataSubject = new BehaviorSubject<EtsShippingData>(shippingMethodStart);
    const shippingStart = new EtsShipping();
    this.shippingSubject = new BehaviorSubject<EtsShipping>(shippingStart);

    const paymentMethodStart = new EtsPaymentData();
    paymentMethodStart.paymentMethods = [];
    this.paymentDataSubject = new BehaviorSubject<EtsPaymentData>(paymentMethodStart);
    const paymentStart = new EtsPayment();
    this.paymentSubject = new BehaviorSubject<EtsPayment>(paymentStart);

    this.personalisationSubject = new BehaviorSubject<EtsPersonalisation[]>([]);
    const headers = this.getHttpHeaders();

    this.http.get(this.apiUrl + 'order/clean', { headers }).pipe(
      take(1)
    ).subscribe(
      () => {
        this.basketService.updateBasket().pipe(
          take(1)
        ).subscribe(() => {
          this.bc.postMessage('updateBasket');
        });
      },
      error => {
        console.log(error);
        this.logService.createLog({
          message: 'orderSuccessfull: Could not finish order',
          apiresponse: error
        });
        this.toastr.error( this.translate.instant('toastr.checkout.orderError'));
      }
    );
  }

  /**
   * Sends an HTTP-Request to API and resets the Eventim-Reservation timer
   * @returns Observable<boolean>
   */
  resetReservationTimer(): Observable<boolean> {
    const headers = this.getHttpHeaders();
    return this.http.post(this.apiUrl + 'order/reset-reservation-timer', null, {headers}).pipe(
      take(1),
      map(
        () => {
          return true;
        },
        () => {
          return false;
        })
    );
  }

  /**
   * Helper function for localstorage clear
   *
   * @returns void
   */
  clearLocalStorage(): void
  {
    localStorage.clear();
    this.bc.postMessage('updateLogout');
  }

  /*
   * Returns the last ordered Print@Home ticket of a customer.
   *
   * @returns Observable<Blob>
   */
  getPrintAtHomeTicket(): Observable<Blob>
  {
    let headers = this.getHttpHeaders();
    headers = headers.append('responseType', 'blob');

    return this.http.get(this.apiUrl + 'order/print-home-pdf', {headers, responseType: 'blob'});
  }


  /**
   * Returns http head with shopId and Authorization Token
   *
   * @returns HttpHeaders
   */
  private getHttpHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.append('partnershopId', this.config.getShopIdentifier());

    // Because raceconditions need to reassign loginToken in loginService
    if ( localStorage.getItem('ets-token-' + this.config.getShopIdentifier()) !== null
      && localStorage.getItem('ets-token-' + this.config.getShopIdentifier()) !== undefined
      && localStorage.getItem('ets-token-' + this.config.getShopIdentifier()) !== '')
    {
      this.login.loginToken.next(localStorage.getItem('ets-token-' + this.config.getShopIdentifier()));
    }

    if (this.login.loginTokenValue !== '') {
      headers = headers.append('Authorization', 'Bearer ' + this.login.loginTokenValue);
    }
    return headers;
  }
}
