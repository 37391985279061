import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { EtsDetailService } from '../ets-detail-service/ets-detail.service';
import { EtsDetailData } from '../ets-detail-service/ets-detail-data';
import { BaseComponent } from '../base-component/base-component';
import { EtsConfigService } from '../ets-config-service/ets-config.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { EtsDetailPackageService } from '../ets-detail-package-service/ets-detail-package.service';
import { EtsDetailPackageData } from '../ets-detail-package-service/ets-detail-package-data';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe, Location } from '@angular/common';
import { LogService } from '../services/LogService.service';

@Component({
  selector: 'app-ets-detail',
  templateUrl: './ets-detail.component.html',
  styleUrls: ['./ets-detail.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class EtsDetailComponent
  extends BaseComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @Input() identifier: string;
  @Input() showTitle: string;
  @Input() showHeadliner: string;
  @Input() showInfoAccordion: string;
  @Input() showTicketing: string;
  @Input() embeddedIn: string;

  details: EtsDetailData | EtsDetailPackageData = null;
  location: string;
  isLoading: boolean;
  loadingSpinner;
  isPackage: boolean;
  moreEventInfo?: string;
  headlineDate: string = '';

  constructor(
    private detailService: EtsDetailService,
    private packageService: EtsDetailPackageService,
    public config: EtsConfigService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public translate: TranslateService,
    public locationService: Location,
    public datepipe: DatePipe,
    private ref: ChangeDetectorRef,
    private logService: LogService
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadDetails();

    // * Needed for use as Widget
    if (this.embedName !== 'subComponent') {
      this.embeddedIn = this.embedName;
    }
  }

  ngAfterViewInit(): void {
    this.spinner.show('detailspinner');
  }

  /**
   * Gets event details
   *
   * @retuns void
   */
  loadDetails(): void {
    this.spinner.show('detailspinner');
    this.isLoading = true;
    this.details = null;
    this.isPackage = false;
    this.config.getShopIdentifierSubject().subscribe((identifer: string) => {
      if (identifer !== '') {
        if (this.identifier.indexOf('777') !== 0) {
          this.detailService.loadDetailData(this.identifier).subscribe(
            (details) => {
              this.details = null;
              this.isLoading = false;
              this.spinner.hide('detailspinner');
              if (details !== null) {
                if (this.loadingSpinner) {
                  clearTimeout(this.loadingSpinner);
                }
                this.details = details;
                if (this.details.infoBox.moreInfoHTML.length > 0) {
                  this.createEventInfo();
                }

                // Adds date to headline of booking site
                if ((this.config.getConfigObject().misc.showCalendar && this.config.getConfigObject().calendar.directBooking)) {
                  this.createHeadlineDate();
                }
              }
              this.ref.markForCheck();
              setTimeout(() => {
                document.querySelector('.etsDetailArtist')?.scrollIntoView({behavior: 'smooth', block: 'center'});
              }, 10);

            },
            (error) => {
              this.logService.createLog({
                message: 'loadDetails: Unable to load event informations',
                apiresponse: error,
                idenfifier: this.identifier
              });
              this.toastr.error(
                this.translate.instant('toastr.events.eventloadingError')
              );
              console.log(error);
              this.spinner.hide('detailspinner');
              if (this.loadingSpinner) {
                clearTimeout(this.loadingSpinner);
              }
              this.isLoading = false;
            }
          );
        } else {
          this.isPackage = true;
          this.packageService.loadPackageDetailData(this.identifier).subscribe(
            (details) => {
              this.details = null;
              this.isLoading = false;
              this.spinner.hide('detailspinner');
              if (details !== null) {
                if (this.loadingSpinner) {
                  clearTimeout(this.loadingSpinner);
                }
                this.details = details;
                this.ref.markForCheck();
                setTimeout(() => {
                  document.querySelector('.etsDetailArtist')?.scrollIntoView({behavior: 'smooth', block: 'center'});
                }, 10);

              }
            },
            (error) => {
              console.log(error);
              this.logService.createLog({
                message: 'loadDetails: Unable to load package informations',
                apiresponse: error,
                idenfifier: this.identifier
              });
              this.toastr.error(
                this.translate.instant('toastr.events.eventloadingError')
              );
              this.spinner.hide('detailspinner');
              if (this.loadingSpinner) {
                clearTimeout(this.loadingSpinner);
              }
              this.isLoading = false;
            }
          );
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !changes.identifier.isFirstChange() &&
      changes.identifier.previousValue !== changes.identifier.currentValue
    ) {
      this.loadingSpinner = setTimeout(
        () => this.spinner.show('detailspinner'),
        10
      );
      setTimeout(() => {
        this.spinner.hide('detailspinner');
      }, 4000);
      this.loadDetails();
    } else if (changes.identifier.isFirstChange()) {
      setTimeout(() => {
        this.spinner.hide('detailspinner');
      }, 2000);
    }
  }
  back(): void {
    this.locationService.back();
  }

  /**
   * creates additional event info text
   *
   * @returns void
   */
  private createEventInfo(): void
  {
    this.moreEventInfo = this.details.infoBox.moreInfoHTML;
  }

  /**
   * Creates Timestamp for Headline if settings are set
   */
  private createHeadlineDate(): void
  {
    let date = this.details.headliner.infoDateString;
    let conf  = this.config.getConfigObject();

    // Show date and time
    if (!this.details.hideDateFlag) {
      if (conf.directbooking.showDate && conf.directbooking.showDateTime) {
        this.headlineDate = this.datepipe.transform(date, 'EE, dd.MM.yyyy, H:mm') + ' Uhr';
      } else if (conf.directbooking.showDate && !conf.directbooking.showDateTime) {
        // Show Date but not time
        this.headlineDate = this.datepipe.transform(date, 'EE, dd.MM.yyyy');
      } else if (!conf.directbooking.showDate && conf.directbooking.showDateTime) {
        //show the time but not the date
        this.headlineDate = this.datepipe.transform(date, 'H:mm') + ' Uhr';
      } else {
        //only shows headline
        this.headlineDate = '';
      }
    }
  }
}
