import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject, throwError} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {EtsConfigService} from '../ets-config-service/ets-config.service';
import {catchError, map, switchMap, take, tap} from 'rxjs/operators';
import {EtsLoginData} from './ets-login-data';
import {EtsBasketService} from '../ets-basket-service/ets-basket-service';
import { EtsCouponData } from '../ets-coupon-service/ets-coupon-data';
import { ToastrService } from 'ngx-toastr';
import { ErrorcodeService } from '../helpers/errorcode-service.service';
@Injectable({
  providedIn: 'root'
})
export class EtsLoginService {
  private loginData: BehaviorSubject<EtsLoginData>;
  public loginToken: BehaviorSubject<string>;
  private loginLoading: BehaviorSubject<boolean>;
  public isLoggedIn: BehaviorSubject<boolean>;
  public isGuest: BehaviorSubject<boolean>;
  public couponSettings: EtsCouponData;

  private bc: BroadcastChannel;

  constructor(
    @Inject('ETS_API_URL') public apiUrl: string,
    private http: HttpClient,
    private config: EtsConfigService,
    public basket: EtsBasketService,
    private toastr: ToastrService,
    private errorMessageService: ErrorcodeService
  ) {
    this.loginData = new BehaviorSubject<EtsLoginData>(new EtsLoginData());
    this.loginToken = new BehaviorSubject<string>('');
    this.loginLoading = new BehaviorSubject<boolean>(false);
    this.isLoggedIn = new BehaviorSubject<boolean>(false);
    this.isGuest = new BehaviorSubject<boolean>(false);

    this.config.getShopIdentifierSubject().subscribe(identifier => {
      if (identifier !== null && identifier !== '') {
        this.bc = new BroadcastChannel('ets_login_channel-' + identifier);

        this.bc.onmessage = (ev) => {
          if (ev.data === 'updateLoginAccount') {
            const oldLogData = localStorage.getItem('ets-login-account-' + identifier);
            this.loginData.next(JSON.parse(oldLogData));
          } else if (ev.data === 'updateLogin') {
            const olderLoginToken = localStorage.getItem('ets-login-token-' + identifier);
            this.loginToken.next(olderLoginToken);
            const oldLogData = localStorage.getItem('ets-login-account-' + identifier);
            this.loginData.next(JSON.parse(oldLogData));
            this.isLoggedIn.next(true);
          } else if (ev.data === 'updateLogout') {
            this.isLoggedIn.next(false);
            this.loginToken.next('');
            this.basket.updateBasket();
          }
        };

        const oldLoginToken = localStorage.getItem('ets-login-token-' + identifier);
        if (oldLoginToken !== null) {
          this.loginToken.next(oldLoginToken);
          this.isLoggedIn.next(true);
        }
        const oldLoginData = localStorage.getItem('ets-login-account-' + identifier);
        if (oldLoginData !== null) {
          this.loginData.next(JSON.parse(oldLoginData));
        }
      }
    });
  }

  public get loginTokenValue(): string {
    return this.loginToken.value;
  }

  public get loginLoadingValue(): boolean {
    return this.loginLoading.value;
  }

  public get loginDataValue(): EtsLoginData {
    return this.loginData.value;
  }

  public get isLoggedInValue(): boolean {
    return this.isLoggedIn.value;
  }

  /**
   * Sends request to API to log in as guest
   * @returns Observable<EtsLoginData>
   */
  doGuestLogin(): Observable<EtsLoginData> {
    this.loginLoading.next(true);

    let guestToken = '';
    if (this.basket.basketTokenValue !== null && this.basket.basketTokenValue !== undefined && this.basket.basketTokenValue !== '' ) {
      guestToken = this.basket.basketTokenValue;
    } else if (localStorage.getItem('ets-token-' + this.config.getShopIdentifier()) !== null
      && localStorage.getItem('ets-token-' + this.config.getShopIdentifier()) !== undefined
      && localStorage.getItem('ets-token-' + this.config.getShopIdentifier()) !== '') {
      guestToken = localStorage.getItem('ets-token-' + this.config.getShopIdentifier());
    }

    guestToken = (guestToken !== '') ? '?guest_token=' + guestToken : '';

    const headers = this.getHttpHeaders();

    let requestUrl = this.apiUrl + 'login' + guestToken;

    requestUrl += (guestToken === '') ? '?orderAsGuest=1' : '&orderAsGuest=1';

    return this.http.get(requestUrl, { headers }).pipe(
      take(1),
      catchError(err => {
        this.loginLoading.next(false);
        return throwError(() => new Error(err));
      }),
      map((data: any) => {
        if (data && data.status && data.status !== 'success') {
        }
        if (data && data.token) {
          this.loginToken.next(data.token);
          localStorage.setItem('ets-login-token-' + this.config.getShopIdentifier(), data.token);
          this.bc.postMessage('updateLogin');

          this.basket.upgradeGuestToken(data.token);
        }
        if (data && data.data) {
          const loginData = {
            id: data.data.id,
            isPrivateIndividual: data.data.isPrivateIndividual,
            salutation: data.data.salutation,
            addressFormText: data.data.addressFormText,
            title: data.data.title,
            title2: data.data.title2,
            forename: data.data.forename,
            nameSuffix: data.data.nameSuffix,
            surname: data.data.surname,
            name3: data.data.name3,
            name4: data.data.name4,
            street: data.data.street,
            houseNumber: data.data.houseNumber,
            houseNumberAddition: data.data.houseNumberAddition,
            zipCode: data.data.zipCode,
            city: data.data.city,
            district: data.data.district,
            zipCodePostOfficeBox: data.data.zipCodePostOfficeBox,
            locationPostOfficeBox: data.data.locationPostOfficeBox,
            postOfficeBox: data.data.postOfficeBox,
            phoneCountryCode: data.data.phoneCountryCode,
            phoneAreaCode: data.data.phoneAreaCode,
            phoneNumber: data.data.phoneNumber,
            phone2CountryCode: data.data.phone2CountryCode,
            phone2AreaCode: data.data.phone2AreaCode,
            phone2Number: data.data.phone2Number,
            faxCountryCode: data.data.faxCountryCode,
            faxAreaCode: data.data.faxAreaCode,
            faxNumber: data.data.faxNumber,
            fax2CountryCode: data.data.fax2CountryCode,
            fax2AreaCode: data.data.fax2AreaCode,
            fax2Number: data.data.fax2Number,
            emailAddress: data.data.emailAddress,
            email2Address: data.data.email2Address,
            countryInternalKey: data.data.countryInternalKey,
            countryText: data.data.countryText,
            language: data.data.language,
            shippingPriority: data.data.shippingPriority,
            birthday: data.data.birthday,
            ecFlag: data.data.ecFlag,
            blocked: data.data.blocked,
            note: data.data.note,
            note2: data.data.note2,
            mobileCountryCode: data.data.mobileCountryCode,
            mobileAreaCode: data.data.mobileAreaCode,
            mobileNumber: data.data.mobileNumber,
            mobile2CountryCode: data.data.mobile2CountryCode,
            mobile2AreaCode: data.data.mobile2AreaCode,
            mobile2Number: data.data.mobile2Number,
            homepage: data.data.homepage,
            homepage2: data.data.homepage2,
            taxId: data.data.taxId,
            taxNumber: data.data.taxNumber,
            guestLogin: data.data.guestLogin,
            password: null,
            passwordOld: null,
            passwordRepeat: null,
            emailAddressRepeat: null
          };

          this.loginData.next(loginData);
          localStorage.setItem('ets-login-account-' + this.config.getShopIdentifier(), JSON.stringify(loginData));
          this.bc.postMessage('updateLoginAccount');
        }
        localStorage.setItem('ets-isGuestOrder-' + this.config.getShopIdentifier(), '1');
        return this.loginDataValue;
      },
      error => {
        console.log(error);
        this.toastr.error(this.errorMessageService.getErrorMessage('login', error.error.errorCode));
        this.loginLoading.next(false);
      }),
      tap(() => this.loginLoading.next(false)),
      tap(() => this.isLoggedIn.next(true)),
      tap(() => this.isGuest.next(true)),
    );
  }

  /**
   * Loging for customer or migrates guest customer to existing customer account
   *
   * @param loginUser string
   * @param loginPassword string
   * @returns Observable<EtsLoginData>
   */
  doLogin(loginUser: string, loginPassword: string): Observable<EtsLoginData> {
    const subject = new Subject<EtsLoginData>();
    this.loginLoading.next(true);
    // * Validate if guest order is set in local storage
    // * => Then we have to migrate the guestorder informations to a normal user
    if (localStorage.getItem('ets-isGuestOrder-' + this.config.getShopIdentifier()) !== undefined
      && localStorage.getItem('ets-isGuestOrder-' + this.config.getShopIdentifier()) !== null  && localStorage.getItem('ets-isGuestOrder-' + this.config.getShopIdentifier()) === '1') {
      this.migrateLogin(loginUser, loginPassword).subscribe( data => {
        const loginData = this.assignCustomerData(data['data']);
        this.loginData.next(loginData);
        this.setLocalStorageParameters(data['data']);
        localStorage.setItem('ets-token-' + this.config.getShopIdentifier(), data['token']);
        localStorage.setItem('ets-login-token-' + this.config.getShopIdentifier(), data['token']);
        localStorage.removeItem('ets-isGuestOrder-' + this.config.getShopIdentifier());
        this.loginToken.next(data['token']);
        this.basket.basketToken.next(data['token']);
        this.isGuest.next(false);
        subject.next(this.loginDataValue);
      }),
      tap(() => this.loginLoading.next(false)),
      tap(() => this.isLoggedIn.next(true)),
      tap(() => this.isGuest.next(false));

    } else {
      let guestToken = '';
      if (this.basket.basketTokenValue !== null && this.basket.basketTokenValue !== undefined && this.basket.basketTokenValue !== '' ) {
        guestToken = this.basket.basketTokenValue;
      } else if (localStorage.getItem('ets-token-' + this.config.getShopIdentifier()) !== null
        && localStorage.getItem('ets-token-' + this.config.getShopIdentifier()) !== undefined
        && localStorage.getItem('ets-token-' + this.config.getShopIdentifier()) !== '') {
        guestToken = localStorage.getItem('ets-token-' + this.config.getShopIdentifier());
      }

      guestToken = (guestToken !== '') ? '?guest_token=' + guestToken : '';
      let headers = this.getHttpHeaders();
      headers = headers.append('x-user', encodeURIComponent(loginUser));
      headers = headers.append('x-password', encodeURIComponent(loginPassword));

      return this.http.get(this.apiUrl + 'login' + guestToken, { headers }).pipe(
        take(1),
        catchError(err => {
          this.loginLoading.next(false);
          return throwError(err);
        }),
        map((data: any) => {
          if (data && data.status && data.status !== 'success') {
            this.toastr.error(this.errorMessageService.getErrorMessage('login', ''));
          }
          if (data && data.token) {

            this.loginToken.next(data.token);
            localStorage.setItem('ets-login-token-' + this.config.getShopIdentifier(), data.token);
            this.bc.postMessage('updateLogin');

            this.basket.upgradeGuestToken(data.token);
          }
          if (data && data.data) {
            const loginData = this.assignCustomerData(data.data);
            this.loginData.next(loginData);
            this.setLocalStorageParameters(loginData);
          }
          return this.loginDataValue;
        },
        error => {
          console.log(error);
          this.toastr.error(this.errorMessageService.getErrorMessage('login', error.error.errorCode));
          this.loginLoading.next(false);
        }),
        tap(() => this.loginLoading.next(false)),
        tap(() => this.isLoggedIn.next(true)),
        tap(() => this.isGuest.next(false)),
      );
    }

    return subject.asObservable();
  }

  /**
   * Migrates guest user to an regular user.
   *
   * @param username string
   * @param password string
   * @returns Observable<boolean>
   */
  migrateLogin(username: string, password: string): Observable<EtsLoginData> {
    const etsToken = localStorage.getItem('ets-token-' + this.config.getShopIdentifier());
    const etsBasket = JSON.parse(localStorage.getItem('ets-basket-' + this.config.getShopIdentifier()));

    const basket = {
      events: []
    };
    etsBasket.items.forEach(event => {
      let priceGroup = '1';

      switch (event.priceGroups.toLowerCase()) {
        case 'normalpreis':
          priceGroup = '1';
          break;
        case 'ermäßigt':
          priceGroup = '2';
          break;
        case 'kind':
          priceGroup = '6';
          break;
        default:
          priceGroup = '1';
      }

      const priceGroups = [];
      for (let i = 0; i < event.seatNos.length; ++i) {
        priceGroups.push(priceGroup);
      }

      const buffer = {
        eventId: event.eventId,
        seats: event.seatNos,
        priceGroups: priceGroups
      };

      basket.events.push(buffer);

    });

    let headers = this.getHttpHeaders();
    headers = headers.append('X-User', username);
    headers = headers.append('X-Password', password);
    headers = headers.append('migrateLogin', '1');
    headers = headers.append('Authorization', 'Bearer ' + etsToken);
    headers = headers.append('basket', JSON.stringify(basket));

    const subject = new Subject<EtsLoginData>();
    this.http.get<EtsLoginData>(this.apiUrl + 'login', { headers }).subscribe( data => {
      if (data) {
        subject.next(data);
        localStorage.removeItem('ets-isGuestOrder-' + this.config.getShopIdentifier());
      }
    },
    error => {
      throwError( () => new Error(error));
    });
    return subject.asObservable();
  }


  /**
   * Assigns data from api to object
   *
   * @param customerData string
   * @returns any
   */
  assignCustomerData(customerData: EtsLoginData): any {

    if (customerData) {
      return {
        id: customerData.id,
        isPrivateIndividual: customerData.isPrivateIndividual,
        salutation: customerData.salutation,
        addressFormText: customerData.addressFormText,
        title: customerData.title,
        title2: customerData.title2,
        forename: customerData.forename,
        nameSuffix: customerData.nameSuffix,
        surname: customerData.surname,
        name3: customerData.name3,
        name4: customerData.name4,
        street: customerData.street,
        houseNumber: customerData.houseNumber,
        houseNumberAddition: customerData.houseNumberAddition,
        zipCode: customerData.zipCode,
        city: customerData.city,
        district: customerData.district,
        zipCodePostOfficeBox: customerData.zipCodePostOfficeBox,
        locationPostOfficeBox: customerData.locationPostOfficeBox,
        postOfficeBox: customerData.postOfficeBox,
        phoneCountryCode: customerData.phoneCountryCode,
        phoneAreaCode: customerData.phoneAreaCode,
        phoneNumber: customerData.phoneNumber,
        phone2CountryCode: customerData.phone2CountryCode,
        phone2AreaCode: customerData.phone2AreaCode,
        phone2Number: customerData.phone2Number,
        faxCountryCode: customerData.faxCountryCode,
        faxAreaCode: customerData.faxAreaCode,
        faxNumber: customerData.faxNumber,
        fax2CountryCode: customerData.fax2CountryCode,
        fax2AreaCode: customerData.fax2AreaCode,
        fax2Number: customerData.fax2Number,
        emailAddress: customerData.emailAddress,
        email2Address: customerData.email2Address,
        countryInternalKey: customerData.countryInternalKey,
        countryText: customerData.countryText,
        language: customerData.language,
        shippingPriority: customerData.shippingPriority,
        birthday: customerData.birthday,
        ecFlag: customerData.ecFlag,
        blocked: customerData.blocked,
        note: customerData.note,
        note2: customerData.note2,
        mobileCountryCode: customerData.mobileCountryCode,
        mobileAreaCode: customerData.mobileAreaCode,
        mobileNumber: customerData.mobileNumber,
        mobile2CountryCode: customerData.mobile2CountryCode,
        mobile2AreaCode: customerData.mobile2AreaCode,
        mobile2Number: customerData.mobile2Number,
        homepage: customerData.homepage,
        homepage2: customerData.homepage2,
        taxId: customerData.taxId,
        taxNumber: customerData.taxNumber,
        guestLogin: customerData.guestLogin,
        password: null,
        passwordOld: null,
        passwordRepeat: null,
        emailAddressRepeat: null
      };
    } else {
      return [];
    }
  }

  /**
   * Save neccessary login informations into localstorage
   *
   * @param loginData any
   * @returns void
   */
  setLocalStorageParameters(loginData): void {
    localStorage.setItem('ets-login-account-' + this.config.getShopIdentifier(), JSON.stringify(loginData));
    this.bc.postMessage('updateLogin');
    this.bc.postMessage('updateLoginAccount');
  }


  /**
   * Logout function for user account
   *
   * @returns Observable<boolean>
   */
  doLogout(): Observable<boolean> {
    this.loginLoading.next(true);
    let headers = this.getHttpHeaders();
    if (this.loginTokenValue !== '') {
      headers = headers.append('Authorization', 'Bearer ' + this.loginTokenValue);
    }
    return this.http.get(this.apiUrl + 'order/clean', { headers }).pipe(
      take(1),
      switchMap( () => {
        return this.basket.updateBasket();
      }),
      take(1),
      switchMap( () => {
        return this.http.get(this.apiUrl + 'logout', { headers });
      }),
      take(1),
      map((data: any) => {
        return !!(data && data.status);
      }),
      tap(() => this.loginLoading.next(false)),
      tap(() => {
        this.isLoggedIn.next(false);
        this.loginToken.next('');
        // Clear all user informations from local and session storage
        this.basket.removeGuestToken();
        localStorage.removeItem('ets-login-token-' + this.config.getShopIdentifier());
        localStorage.removeItem('ets-login-account-' + this.config.getShopIdentifier());
        localStorage.removeItem('ets-basket-' + this.config.getShopIdentifier());
        localStorage.removeItem('ets-basket-urls-' + this.config.getShopIdentifier());
        localStorage.removeItem('ets-payment-' + this.config.getShopIdentifier());
        localStorage.removeItem('ets-shipping-' + this.config.getShopIdentifier());
        localStorage.removeItem('ets-token-' + this.config.getShopIdentifier());
        localStorage.removeItem('ets-isGuestOrder-' + this.config.getShopIdentifier());
        localStorage.removeItem('ets-promotionCodeAccepted-' + this.config.getShopIdentifier());
        localStorage.removeItem('ets-personalisation-' + this.config.getShopIdentifier());
        sessionStorage.clear();
        this.bc.postMessage('updateLogout');
      }),
    );
  }

  /**
   * Updates customer informations
   * @param updateCustomer EtsLoginData
   * @returns Observable<boolean>
   */
  updateCustomer(updateCustomer: EtsLoginData): Observable<boolean> {
    let headers = this.getHttpHeaders();
    headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
    if (this.loginTokenValue !== '') {
      headers = headers.append('Authorization', 'Bearer ' + this.loginTokenValue);
    }
    const updateCustomerString = this.etsDataToString(updateCustomer);
    this.loginLoading.next(true);

    return this.http.put(this.apiUrl + 'customer', updateCustomerString, { headers }).pipe(
      take(1),
      map((data: any) => {
        if (data && data.status && data.status === 'failure') {
          return false;
        }
        if (data.newToken !== undefined && data.newToken !== null && data.newToken !== false && data.newToken !== '') {
          localStorage.setItem('ets-token-' + this.config.getShopIdentifier(), data.newToken);
          localStorage.setItem('ets-login-token-' + this.config.getShopIdentifier(), data.newToken);
          this.loginToken.next(data.newToken);
          this.basket.basketToken.next(data.newToken);
        }
        this.loginData.next(updateCustomer);
        localStorage.setItem('ets-login-account-' + this.config.getShopIdentifier(), JSON.stringify(updateCustomer));
        this.bc.postMessage('updateLoginAccount');
        return true;
      },
      error => {
        console.error(error);
      }),
      tap(() => {
        this.loginLoading.next(false);
      }),
    );
  }

  /**
   * Registers an new customer
   *
   * @param registerCustomer EtsLoginData
   * @returns Observable<boolean>
   */
  registerCustomer(registerCustomer: EtsLoginData): Observable<boolean> {
    let headers = this.getHttpHeaders();
    headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
    if (this.loginTokenValue !== '') {
      headers = headers.append('Authorization', 'Bearer ' + this.loginTokenValue);
    }
    const updateCustomerString = this.etsDataToString(registerCustomer);
    this.loginLoading.next(true);

    return this.http.post(this.apiUrl + 'customer', updateCustomerString, { headers }).pipe(
      take(1),
      map((data: any) => {
        if (data && data.status && data.status === 'failure') {
          return false;
        }

        this.loginData.next(registerCustomer);
        return true;
      }),
      tap(() => {
        this.loginLoading.next(false);
      }),
    );
  }

  /**
   * Returns essential httpheader with partnershopID
   *
   * @returns HttpHeaders
   */
  private getHttpHeaders(): HttpHeaders {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.append('partnershopid', this.config.getShopIdentifier());
    return httpHeaders;
  }

  /**
   * Converts EtsLoginData object to json
   *
   * @param etsData EtsLoginData
   * @returns string
   */
  private etsDataToString(etsData: EtsLoginData): string {
    const formData: any = new URLSearchParams();
    formData.set('salutation', etsData.salutation);
    formData.set('title', etsData.title);
    formData.set('firstname', etsData.forename);
    formData.set('lastname', etsData.surname);
    formData.set('nameAddition', etsData.nameSuffix);
    formData.set('name4', etsData.name4);
    formData.set('streetName', etsData.street);
    formData.set('streetNumber', etsData.houseNumber);
    formData.set('zip', etsData.zipCode);
    formData.set('city', etsData.city);
    formData.set('country', etsData.countryInternalKey);
    formData.set('areaCode', etsData.phoneAreaCode);
    formData.set('phoneNumber', etsData.phoneNumber);
    formData.set('email', etsData.emailAddress);
    formData.set('g-recaptcha-response', 'valid');
    if (etsData.emailAddressRepeat) {
      formData.set('emailRepeat', etsData.emailAddressRepeat);
    }
    if (etsData.passwordOld) {
      formData.set('passwordOld', etsData.passwordOld);
    }
    if (etsData.password) {
      formData.set('password', etsData.password);
    }
    if (etsData.passwordRepeat) {
      formData.set('passwordRepeat', etsData.passwordRepeat);
    }

    return formData.toString();
  }
}
