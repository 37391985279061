import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { EtsConfigService } from '../ets-config-service/ets-config.service';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor(private httpClient: HttpClient, private configService: EtsConfigService) {}

  public createLog(message: any): void
  {
    message.partnershopId = this.configService.getShopIdentifier();

    if (localStorage.getItem('ets-login-account-' + this.configService.getShopIdentifier())) {
      let customerId = JSON.parse(localStorage.getItem('ets-login-account-' + this.configService.getShopIdentifier())).id ?? null

      if (customerId !== null) {
        message.customerId = customerId
      }
    }

    if (localStorage.getItem('ets-token-' + this.configService.getShopIdentifier())) {
      message.bearerToken = localStorage.getItem('ets-token-' + this.configService.getShopIdentifier());
    }

    let headers = this.getHttpHeaders();
    this.httpClient.post<any>(environment.ETS_API_URL + 'log', this.encode(JSON.stringify(message)), {headers}).subscribe(() => {},
      err => {console.log('Could not log error');
    });

  }

  private encode(str) {
    return window.btoa(unescape(encodeURIComponent(str)));
  }

  /**
   * Returns http head with shopId and Authorization Token
   *
   * @returns HttpHeaders
   */
  private getHttpHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.append('partnershopId', this.configService.getShopIdentifier());
    headers = headers.append('X-Secret', btoa(Math.round(Date.now() / 1000).toString()).split('').reverse().join(''));
    headers = headers.append('Content-Type', 'text/plain');


    return headers;
  }

}
