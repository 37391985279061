import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable, timer} from 'rxjs';
import {EtsBasketData, EtsBasketUrl, EtsChosenShipping} from './ets-basket-data';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {EtsConfigService} from '../ets-config-service/ets-config.service';
import {map, switchMap, take, tap} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {EtsComponentRouterService} from '../ets-component-router-service/ets-component-router.service';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
  providedIn: 'root'
})
export class EtsBasketService {
  private basketSubject: BehaviorSubject<EtsBasketData>;
  public basketToken: BehaviorSubject<string>;
  private timerString: BehaviorSubject<string>;
  private basketUrlSubject: BehaviorSubject<EtsBasketUrl[]>;
  public basketLoading: BehaviorSubject<boolean>;
  public isCheckout: boolean;
  public chosenShipping: number;

  everySecond: Observable<number> = timer(0, 1000);
  public bc: BroadcastChannel;

  constructor(
    @Inject('ETS_API_URL') public apiUrl: string,
    private http: HttpClient,
    private config: EtsConfigService,
    private toastr: ToastrService,
    private etsRouter: EtsComponentRouterService,
    public translate: TranslateService,
  ) {
    let startBasket = new EtsBasketData();
    startBasket.items = [];
    startBasket.shipping = [];
    startBasket.totalPrice = 0;
    this.isCheckout = false;
    const timerend = new Date();
    timerend.setMinutes(timerend.getMinutes() + 20);
    startBasket.timerEnd = Math.round(timerend.getTime()  / 1000);

    this.basketSubject = new BehaviorSubject<EtsBasketData>(startBasket);
    this.basketToken = new BehaviorSubject<string>('');
    this.basketLoading = new BehaviorSubject<boolean>(false);
    this.timerString = new BehaviorSubject<string>('20:00');
    this.basketUrlSubject = new BehaviorSubject<EtsBasketUrl[]>([]);
    this.config.getShopIdentifierSubject().subscribe(identifier => {
      if (identifier !== null && identifier !== '') {
        const oldBasket = localStorage.getItem('ets-basket-' + this.config.getShopIdentifier());
        if (oldBasket !== null) {
          startBasket = JSON.parse(oldBasket);
        }
        this.basketSubject = new BehaviorSubject<EtsBasketData>(startBasket);
        this.basketToken = new BehaviorSubject<string>('');
        this.basketLoading = new BehaviorSubject<boolean>(false);
        this.timerString = new BehaviorSubject<string>('20:00');

        const oldBasketToken = localStorage.getItem('ets-token-' + identifier);
        if (oldBasketToken !== null) {
          this.basketToken.next(oldBasketToken);
        }
        const oldBasketUrls = localStorage.getItem('ets-basket-urls-' + identifier);
        if (oldBasketUrls !== null) {
          this.basketUrlSubject.next(JSON.parse(oldBasketUrls));
        }

        this.everySecond.subscribe(() => {
          const timerenda = this.basketSubjectValue.timerEnd - Math.round(new Date().getTime() / 1000);
          const minutes = Math.floor(timerenda / 60);
          const seconds = timerenda % 60;
          if (minutes <= 0 && seconds <= 0) {
            this.timerString.next('00:00');
          } else {
            this.timerString.next(minutes + ':' + ((seconds > 9) ? '' : '0') + seconds);
          }
        });

        this.bc = new BroadcastChannel('ets_basket_channel-' + identifier);
        this.bc.onmessage = (ev) => {
          if (ev.data === 'updateBasket') {
            this.updateBasket().pipe(take(1)).subscribe();
          } else if (ev.data === 'removeGuestToken') {
            localStorage.removeItem('ets-token-' + this.config.getShopIdentifier());
            this.basketToken.next('');
          } else if (ev.data === 'upgradeGuestToken') {
            const token = localStorage.getItem('ets-token-' + this.config.getShopIdentifier());
            this.basketToken.next(token);
          }
        };

        if (oldBasket !== null) {
          this.basketLoading.next(true);
          setTimeout(() => this.updateBasket().pipe(
            take(1)).subscribe(
            data => {
              if (data) {
                this.basketLoading.next(false);
              }
            },
            error => {
              this.createLog({
                message: 'BasketService-Constructor: Could not update basket',
                apiresponse: JSON.stringify(error),
              });
              console.log(error);
              this.basketLoading.next(false);
            }));
        }
      }
    });
  }

  public get basketSubjectValue(): EtsBasketData {
    return this.basketSubject.value;
  }

  public get basketTokenValue(): string {
    return this.basketToken.value;
  }

  public get timerStringValue(): string {
    return this.timerString.value;
  }

  public get basketLoadingValue(): boolean {
    return this.basketLoading.value;
  }

  public upgradeGuestToken(token: string): void {
    localStorage.setItem('ets-token-' + this.config.getShopIdentifier(), token);
    this.basketToken.next(token);
    this.bc.postMessage('upgradeGuestToken');
  }

  removeGuestToken(): void {
    localStorage.removeItem('ets-token-' + this.config.getShopIdentifier());
    this.basketToken.next('');
    this.bc.postMessage('removeGuestToken');
  }

  urlToEventId(eventId: number): string | null {
    const currentBasketUrls = this.basketUrlSubject.value;
    if (currentBasketUrls.findIndex(x => x.eventId === eventId) >= 0) {
      return currentBasketUrls[currentBasketUrls.findIndex(x => x.eventId === eventId)].url;
    }

    return null;
  }

  /**
   * Adds new event to basket
   *
   * @param basketData string
   * @param eventId string
   * @returns Observable<Object>
   */
  addToBasket(basketData: string, eventId: string): Observable<boolean> {
    if (this.basketLoadingValue) {
      return null;
    }
    this.basketLoading.next(true);
    let headers = this.getHttpHeaders();
    headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');

    return this.http.post(this.apiUrl + 'cart', basketData, { headers }).pipe(
      take(1),
      switchMap((data: any) => {
        if (data && data.status && data.status !== 'success') {
          this.createLog({
            message: 'addToBasket: Could not add event to basket',
            apiresponse: data,
            basketdata: basketData,
            eventid: eventId
          });
          this.basketLoading.next(false);
        }
        if (data && data.token) {
          this.basketToken.next(data.token);
          localStorage.setItem('ets-token-' + this.config.getShopIdentifier(), data.token);

          this.bc.postMessage('upgradeGuestToken');

          if (this.config.getConfigObject().detail.keepEventUrl) {
            const currentBasketUrls = this.basketUrlSubject.value;
            if (currentBasketUrls.findIndex(x => x.eventId === data.eventId) < 0) {
              currentBasketUrls.push({
                eventId: parseInt(eventId, 10),
                url: location.href
              });
              this.basketUrlSubject.next(currentBasketUrls);
              localStorage.setItem('ets-basket-urls-' + this.config.getShopIdentifier(), JSON.stringify(currentBasketUrls));
            }
          }
        }
        return this.updateBasket();
      }),
      tap(() => {
        this.basketLoading.next(false);
      })
    );
  }

  /**
   * Syncs basket with eventim InHouse-API
   *
   * @returns Observable<boolean>
   */
  public updateBasket(): Observable<boolean> {
    const headers = this.getHttpHeaders();

    return this.http.get(
      this.apiUrl + 'cart',
      { headers }
      ).pipe(
        take(1),
        map((data: any) => {
          const etsBasket = new EtsBasketData();
          etsBasket.items = [];
          etsBasket.shipping = [];
          etsBasket.totalPrice = 0;

          // * Sets a new timer if no one already exists
          // * Looking for existing timer instance in session storage
          if (sessionStorage.getItem('ets-timer-' + this.config.getShopIdentifier()))
          {
            etsBasket.timerEnd = Number(sessionStorage.getItem('ets-timer-' + this.config.getShopIdentifier()));
            this.basketSubjectValue.timerEnd = etsBasket.timerEnd;
            this.timerString.next(Math.round(etsBasket.timerEnd / 1000).toString());
          } else {
            // * The reservation validation timer is set to 20 minutes after an event is added to the basket.
            if (data && data.data && data.data.items)  {
              const timerend = new Date();
              timerend.setMinutes(timerend.getMinutes() + 20);
              etsBasket.timerEnd = Math.round(timerend.getTime() / 1000);
              this.timerString.next(etsBasket.timerEnd.toString());
              sessionStorage.setItem('ets-timer-' + this.config.getShopIdentifier(), etsBasket.timerEnd.toString());
            }
          }

          if (data && data.data && data.data.items) {
            for (let item of data.data.items) {
              item.showActions = true;
              // Special check for packages Package for removing action button in cart.
              if (String(item.eventId).startsWith('-')) {
                let found = etsBasket.items.find(({eventId}) => {
                  return eventId == item.eventId;
                });

                if (found) {
                  item.showActions = false;
                }
              }
              etsBasket.items.push(item);
            }
          }
          if (data && data.data && data.data.shipping_methods) {
            etsBasket.shipping.push(...data.data.shipping_methods);
          }
          if (data && data.data && data.data.total_price) {
            etsBasket.totalPrice = data.data.total_price;
          }

          if (data && data.data && data.data.promocode
              && typeof data.data.promocode === 'object' && Object.keys(data.data.promocode).length >= 1) {
            let promocode = String(Object.values(data.data.promocode)[0]);
            if (promocode) {
              etsBasket.promotionCode = promocode;
            }
          }

          if (localStorage.getItem('ets-basket-' + this.config.getShopIdentifier())) {
            let etsBasketTotal = JSON.parse(localStorage.getItem('ets-basket-' + this.config.getShopIdentifier()));
            etsBasket.chosenShipping = etsBasketTotal.chosenShipping;
          }

          localStorage.setItem('ets-basket-' + this.config.getShopIdentifier(), JSON.stringify(etsBasket));
          this.basketSubject.next(etsBasket);

          this.createLog({
            message: 'updateBasket: New Basket received',
            apiresponse: data,
          });

          return true;
        })
      );
  }

  /**
   * Removes event from basket
   *
   * @param deleteParameter string
   * @param isCheckout boolean
   * @returns void
   */
  deleteFromBasket(deleteParameter: string, isCheckout: boolean = false ): void {
    if (this.basketLoadingValue) {
      return;
    }
    this.basketLoading.next(true);
    const headers = this.getHttpHeaders();

    this.http.delete(this.apiUrl + 'cart?data=' + deleteParameter, { headers }).pipe(
      take(1),
      switchMap((data: any) => {
        if (data && data.status && data.status !== 'success') {

          this.createLog({
            message: 'deleteFromBasket: Could not delete event from basket',
            apiresponse: data,
            deleteparameter: deleteParameter,
            ischeckout: isCheckout
          });
        }
        if (data && data.token) {
          this.basketToken.next(data.token);
        }
        return this.updateBasket();
      }),
      tap(() => {
        if (this.basketSubjectValue.items.length <= 0) {
          localStorage.removeItem('ets-shipping-' + this.config.getShopIdentifier());

          localStorage.removeItem('ets-basket-' + this.config.getShopIdentifier());

          if (localStorage.getItem('ets-isGuestOrder-' + this.config.getShopIdentifier()) === '1') {
            localStorage.clear();
          }
          sessionStorage.removeItem('ets-timer-' + this.config.getShopIdentifier());
          if (isCheckout || this.isCheckout) {
            this.isCheckout = false;
            this.etsRouter.resetToHome();
          }
        }
        this.basketLoading.next(false);
      }),
    ).subscribe(
      () => {
        this.bc.postMessage('updateBasket');
      },
      error => {
        this.createLog({
          message: 'deleteFromBasket: Could not delete event from basket',
          deleteparameter: deleteParameter,
          apiresponse: error,
          ischeckout: isCheckout
        });
        if (error.status === 400 && error.error.errors.length >= 2) {
          if (error.error.errors[1] === '-221') {
            this.basketLoading.next(false);
            this.toastr.error(this.translate.instant('toastr.basket.clearError-221'))
          } else if (error.error.errors[1] === '-204') {
            this.basketLoading.next(false);
            this.updateBasket();
          } else {
            this.toastr.error(this.translate.instant('toastr.basket.clearError'));
            console.log(error);
          }
        } else {
          this.toastr.error(this.translate.instant('toastr.basket.clearError'));
          this.updateBasket();
          console.log(error);
        }
      }
    );
  }

  /**
   * Removes chosen event from basket redirect to eventpage
   *
   * @param deleteParameter string
   * @param href  string
   * @returns void
   */
  deleteFromBasketWithRedirect(deleteParameter: string, href: string): void {

    if (this.basketLoadingValue) {
      return;
    }

    this.basketLoading.next(true);
    const headers = this.getHttpHeaders();

    this.http.delete(this.apiUrl + 'cart?data=' + deleteParameter, { headers }).pipe(
      take(1),
      switchMap((data: any) => {
        if (data && data.status && data.status !== 'success') {
          this.createLog({
            message: 'deleteFromBasketWithRedirect: Could not delete event from Basket',
            apiresponse: data,
            deleteparameter: deleteParameter,
            url: href
          });
        }
        if (data && data.token) {
          this.basketToken.next(data.token);
        }

        return this.updateBasket();
      }),
      tap(() => {
        this.basketLoading.next(false);
      }),
    ).subscribe(
      () => {
        this.bc.postMessage('updateBasket');

        // Get query parameter from event url
        const urlParams = new URLSearchParams(
          new URL(href).search
        );

        // if query parameters present append edit flag
        if (Array.from(urlParams).length >= 1) {
          window.location.href = href + '&edit=true';
        } else {
          window.location.href = href + '?edit=true';
        }

      },
      error => {
        console.log(error);
        this.createLog({
          message: 'deleteFromBasketWithRedirect: Could not delete event from Basket',
          apiresponse: error,
          deleteparameter: deleteParameter,
          url: href
        });

        if (error.error.errors[1] === '-221') {
          this.basketLoading.next(false);
          this.toastr.error(this.translate.instant('toastr.basket.clearError-221'))
        } else {
          this.toastr.error(this.translate.instant('toastr.basket.clearError'))
        }
        this.basketLoading.next(false);
        this.updateBasket().pipe(take(1)).subscribe();
      }
    );
  }

  /**
   * Sets chosen shipping method to basketservice and localstorage
   *
   * @param shipping EtsChosenShipping
   * @returns void
   */
  setShippingToOrder(shipping: EtsChosenShipping): void {
    const etsBasket = this.basketSubjectValue;
    etsBasket.chosenShipping = [shipping];

    localStorage.setItem('ets-basket-' + this.config.getShopIdentifier(), JSON.stringify(etsBasket));
    this.bc.postMessage('updateBasket');
    this.basketSubject.next(etsBasket);
  }

  /**
   *  Calls Partnershop-API and redeems given promotioncode.
   *
   * @param code
   * @returns Observable<boolean>
   */
  redeemPromotionCode(code: string): Observable<boolean>
  {
    this.basketLoading.next(true);
    let headers = this.getHttpHeaders();
    return this.http.put(this.apiUrl + 'cart/promotioncode',{promotioncode: code}, {headers}).pipe(
      take(1),
      map( (data: any) => {
        return true;
      },
      err => {
        console.log(err);
        this.createLog({
          message: 'redeemPromotionCode: Could not redeem promo code',
          apiresponse: err,
          promotioncode: code
        });
        this.basketLoading.next(false);
        this.toastr.error(this.getPromotioncodeErrors(err.status, err.error.errorCode));
        return false;
      })
    );
  }

  /**
   *
   * @returns Observable<boolean>
   */
  cancelPromotionCode(): Observable<boolean>
  {
    if (this.basketSubjectValue.promotionCode === null) {
      return new BehaviorSubject<boolean>(false);
    }

    this.basketLoading.next(true);
    let headers = this.getHttpHeaders();
    return this.http.put(this.apiUrl + 'cart/remove-promotioncode', null, {headers}).pipe(
      take(1),
      map( (data: any) => {
        this.basketSubjectValue.promotionCode = '';
        this.updateBasket();
        this.basketLoading.next(false);
        return true;
      },
      err => {
        this.createLog({
          message: 'cancelPromotionCode: Could not cancel promo code',
          apiresponse: err,
        });
        console.log(err);
        this.toastr.error(this.getPromotioncodeErrors(err.status, err.error.errorCode));
        this.basketLoading.next(false);
        return false;
      })
    );
  }

  /**
   * Returns header with partnershopID for HTTP-Requests
   *
   * @returns HttpHeaders
   */
  private getHttpHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.append('partnershopId', this.config.getShopIdentifier());
    if (this.basketTokenValue !== '') {
      headers = headers.append('Authorization', 'Bearer ' + this.basketTokenValue);
    }

    return headers;
  }

  /**
   * Returns translation for given promotioncode error.
   *
   * @param state
   * @param errorCode
   * @returns string
   */
  getPromotioncodeErrors(state: number, errorCode: string): string
  {
    switch(state) {
      case 400: {
        if (errorCode === 'Promo3') {
          return this.translate.instant('errorMessage.promotioncodes.Promo3');
        }
      }
      case 403: {
        if (errorCode === 'Promo4') {
          return this.translate.instant('errorMessage.promotioncodes.Promo4');
        }
      }
      case 404: {
        if (errorCode === 'Promo2') {
          return this.translate.instant('errorMessage.promotioncodes.Promo2');
        } else if (errorCode === 'Promo7') {
          return this.translate.instant('errorMessage.promotioncodes.Promo7');
        }
      }
      case 500: {
        if (errorCode === 'Promo1') {
          return this.translate.instant('errorMessage.promotioncodes.Promo1');
        } else if (errorCode === 'Promo6') {
          return this.translate.instant('errorMessage.promotioncodes.Promo6');
        }
      }
      default:
        return this.translate.instant('errorMessage.promotioncodes.general');
    }
  }

  public createLog(message: any): void {
    message.partnershopId = this.config.getShopIdentifier();

    if (localStorage.getItem('ets-login-account-' + this.config.getShopIdentifier())) {
      let customerId = JSON.parse(localStorage.getItem('ets-login-account-' + this.config.getShopIdentifier())).id ?? null

      if (customerId !== null) {
        message.customerId = customerId
      }
    }

    if (localStorage.getItem('ets-token-' + this.config.getShopIdentifier())) {
      message.bearerToken = localStorage.getItem('ets-token-' + this.config.getShopIdentifier());
    }

    let headers = this.getLoghHttpHeaders();
    this.http.post<any>(this.apiUrl + 'log', this.encode(JSON.stringify(message)), {headers}).subscribe(() => {},
      err => {console.log('Could not log error');
    });

  }

  private encode(str) {
    return window.btoa(unescape(encodeURIComponent(str)));
  }

  /**
   * Returns http head with shopId and Authorization Token
   *
   * @returns HttpHeaders
   */
  private getLoghHttpHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.append('partnershopId', this.config.getShopIdentifier());
    headers = headers.append('X-Secret', btoa(Math.round(Date.now() / 1000).toString()).split('').reverse().join(''));
    headers = headers.append('Content-Type', 'text/plain');
    return headers;
  }
}
