import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import {NgModule, DoBootstrap, Injector, DEFAULT_CURRENCY_CODE, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {environment} from '../environments/environment';

import {AppComponent} from './app.component';
import {createCustomElement} from '@angular/elements';
import { EtsDetailComponent } from './ets-detail/ets-detail.component';
import { EtsAccordionComponent } from './ets-accordion/ets-accordion.component';
import { EtsAccordionChildComponent } from './ets-accordion-child/ets-accordion-child.component';
import { EtsDetailHeadlinerComponent } from './ets-detail-headliner/ets-detail-headliner.component';
import {EtsDetailService} from './ets-detail-service/ets-detail.service';
import {EtsTicketService} from './ets-ticket-service/ets-ticket-service';
import { EtsTicketComponent } from './ets-ticket/ets-ticket.component';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { APP_BASE_HREF, DatePipe, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { EtsFullShopComponent } from './ets-full-shop/ets-full-shop.component';
import {EtsComponentRouterComponent} from './ets-component-router/ets-component-router.component';
import {EtsComponentRouterService} from './ets-component-router-service/ets-component-router.service';
import {BaseComponent} from './base-component/base-component';
import {EtsDetailOuterComponent} from './ets-detail/ets-detail-outer.component';
import {EtsListingOuterComponent} from './ets-listing/ets-listing-outer.component';
import {BaseRouterComponent} from './base-component/base-router-component';
import { EtsRouterLinkDirective } from './ets-component-router-service/ets-router-link.directive';
import { EtsConfigComponent } from './ets-config/ets-config.component';
import {EtsConfigService} from './ets-config-service/ets-config.service';
import { EtsMenuButtonsComponent } from './ets-menu-buttons/ets-menu-buttons.component';
import { EtsMenuButtonsOuterComponent } from './ets-menu-buttons/ets-menu-buttons-outer.component';
import {EtsFullShopService} from './ets-full-shop-service/ets-full-shop.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { EtsSearchComponent } from './ets-search/ets-search.component';
import {EtsMessageComponent} from './ets-message/ets-message.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { EtsCheckoutComponent } from './ets-checkout/ets-checkout.component';
import { ToastrModule } from 'ngx-toastr';
import { EtsBasketDetailComponent } from './ets-basket-detail/ets-basket-detail.component';
import { EtsOverlayComponent } from './ets-overlay/ets-overlay.component';
import {EtsTicketOuterComponent} from './ets-ticket/ets-ticket-outer.component';
import {EtsCheckoutOuterComponent} from './ets-checkout/ets-checkout-outer.component';
import {EtsDetailHeadlinerOuterComponent} from './ets-detail-headliner/ets-detail-headliner-outer.component';
import {EtsBasketDetailOuterComponent} from './ets-basket-detail/ets-basket-detail-outer.component';
import {EtsLoginService} from './ets-login-service/ets-login-service';
import {EtsProfileComponent} from './ets-profile/ets-profile.component';
import {EtsProfileOuterComponent} from './ets-profile/ets-profile-outer.component';
import {EtsFooterComponent} from './ets-footer/ets-footer.component';
import {RouterModule} from '@angular/router';
import { EtsHeaderComponent } from './ets-header/ets-header.component';
import { SafePipe } from './safe.pipe';
import { SwiperModule } from 'swiper/angular';
import { EtsCalendarComponent, EtsCalendarHeader } from './ets-calendar/ets-calendar.component';
import { EtsSearchResultsComponent } from './ets-search-results/ets-search-results.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { EtsCalendarOuterComponent } from './ets-calendar/ets-calendar-outer.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { EtsListingComponent } from './ets-listing/ets-listing.component';
import { AllowHTMLPipe } from './allowhtml.pipe';
import { CustomDateAdapter } from './ets-calendar/custom.date.adapter';
import { EtsMaintenancePageComponent } from './ets-maintenance-page/ets-maintenance-page.component';


registerLocaleData(localeDe);

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, environment.LANGS_URL, '.json');
}

@NgModule({
    declarations: [
        BaseComponent,
        BaseRouterComponent,
        AppComponent,
        EtsDetailComponent,
        EtsAccordionComponent,
        EtsAccordionChildComponent,
        EtsDetailHeadlinerComponent,
        EtsTicketComponent,
        EtsListingComponent,
        EtsFullShopComponent,
        EtsComponentRouterComponent,
        EtsDetailOuterComponent,
        EtsListingOuterComponent,
        EtsRouterLinkDirective,
        EtsConfigComponent,
        EtsMenuButtonsComponent,
        EtsMenuButtonsOuterComponent,
        EtsSearchComponent,
        EtsMessageComponent,
        EtsCheckoutComponent,
        EtsBasketDetailComponent,
        EtsOverlayComponent,
        EtsTicketOuterComponent,
        EtsCheckoutOuterComponent,
        EtsDetailHeadlinerOuterComponent,
        EtsBasketDetailOuterComponent,
        EtsProfileComponent,
        EtsProfileOuterComponent,
        EtsFooterComponent,
        EtsHeaderComponent,
        SafePipe,
        AllowHTMLPipe,
        EtsSearchResultsComponent,
        EtsCalendarComponent,
        EtsSearchResultsComponent,
        EtsCalendarOuterComponent,
        EtsCalendarHeader,
        EtsMaintenancePageComponent
    ],
    imports: [
        DatePipe,
        BrowserModule,
        ReactiveFormsModule,
        SwiperModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatIconModule,
        MatButtonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            defaultLanguage: 'de',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
        }),
        FormsModule,
        RouterModule.forRoot([]),
        NgxSpinnerModule,
        ReactiveFormsModule,
        ToastrModule.forRoot({
            positionClass: 'toast-top-full-width',
            toastClass: 'ngx-toastr etsShopToast',
            closeButton: true,
            tapToDismiss: true,
            enableHtml: true,
        }),
      ],
      schemas: [CUSTOM_ELEMENTS_SCHEMA],
    // tslint:disable-next-line:max-line-length
    providers: [
      DatePipe,
      EtsDetailService,
      EtsLoginService,
      EtsTicketService,
      { provide: LOCALE_ID, useValue: 'de-DE' },
      { provide: DEFAULT_CURRENCY_CODE, useValue: '' },
      EtsComponentRouterService,
      EtsConfigService,
      { provide: 'ETS_API_URL', useValue: environment.ETS_API_URL },
      { provide: 'ETS_MEDIA_URL', useValue: environment.ETS_MEDIA_URL },
      { provide: APP_BASE_HREF, useValue: '/' },
      { provide: DateAdapter, useClass: CustomDateAdapter},
      EtsFullShopService,
    ],
})
export class AppModule implements DoBootstrap {

  constructor(private injector: Injector) {

  }

  ngDoBootstrap(): void {
    const etsDetailComponent = createCustomElement(EtsDetailOuterComponent, {injector: this.injector});
    const etsListingComponent = createCustomElement(EtsListingOuterComponent, {injector: this.injector});
    const etsFullShopComponent = createCustomElement(EtsFullShopComponent, {injector: this.injector});
    const etsConfigComponent = createCustomElement(EtsConfigComponent, {injector: this.injector});
    const menuButtonsComponent = createCustomElement(EtsMenuButtonsOuterComponent, {injector: this.injector});
    const etsTicketComponent = createCustomElement(EtsTicketOuterComponent, {injector: this.injector});
    const etsCheckoutComponent = createCustomElement(EtsCheckoutOuterComponent, {injector: this.injector});
    const etsHeadlinerComponent = createCustomElement(EtsDetailHeadlinerOuterComponent, {injector: this.injector});
    const etsBasketDetailComponent = createCustomElement(EtsBasketDetailOuterComponent, {injector: this.injector});
    const etsProfileComponent = createCustomElement(EtsProfileOuterComponent, {injector: this.injector});
    const etsFooterComponent = createCustomElement(EtsFooterComponent, {injector: this.injector});
    const etsSearchResultsComponent = createCustomElement(EtsSearchResultsComponent, {injector: this.injector});
    const etsCalendarComponent = createCustomElement(EtsCalendarOuterComponent, {injector: this.injector});
    customElements.define('ets-detail', etsDetailComponent);
    customElements.define('ets-listing', etsListingComponent);
    customElements.define('ets-shop', etsFullShopComponent);
    customElements.define('ets-config', etsConfigComponent);
    customElements.define('ets-menu-buttons', menuButtonsComponent);
    customElements.define('ets-ticket', etsTicketComponent);
    customElements.define('ets-checkout', etsCheckoutComponent);
    customElements.define('ets-headliner', etsHeadlinerComponent);
    customElements.define('ets-basket-detail', etsBasketDetailComponent);
    customElements.define('ets-profile', etsProfileComponent);
    customElements.define('ets-footer', etsFooterComponent);
    customElements.define('ets-search-results', etsSearchResultsComponent);
    customElements.define('ets-calendar', etsCalendarComponent);
  }
}
