import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {EtsComponentRouterData} from './ets-component-router-data';
import {HttpUrlEncodingCodec} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EtsComponentRouterService {

  protected currentRouteStructure: { [embedUrlName: string]: EtsComponentRouterData };
  protected structureObservable: BehaviorSubject<{ [embedUrlName: string]: EtsComponentRouterData}>;

  protected urlPrefix = 'ets';

  protected codec: HttpUrlEncodingCodec;

  private static upperCaseFirst(input: string): string {
    if (input && input.length > 0) {
      return input.charAt(0).toUpperCase() + input.slice(1);
    }
    return input;
  }

  public generateUrlName(input: string): string {
    return this.urlPrefix + EtsComponentRouterService.upperCaseFirst(input);
  }

  public constructor() {
    this.currentRouteStructure = {};
    this.structureObservable = new BehaviorSubject<{ [embedUrlName: string]: EtsComponentRouterData}>({});

    this.codec = new HttpUrlEncodingCodec();
  }

  public registerEmbed(name: string): void {
    const urlName = this.generateUrlName(name);
    if (!this.currentRouteStructure.hasOwnProperty(urlName)) {
      this.currentRouteStructure[urlName] = {
        embedName: name,
        currentComponent: '',
        params: []
      };
      this.readUrl();
    }
  }

  public urlString(moves: { name: string, target: string, base?: string }[]): string {
    const tempStructure = JSON.parse(JSON.stringify(this.currentRouteStructure));
    let urlParams: URLSearchParams = new URLSearchParams(window.location.search);
    const deletes: string[] = [];
    for (const move of moves) {
      const urlName = this.generateUrlName(move.name);

      urlParams.forEach((value, key) => {
        if (key === urlName) {
          deletes.push(key);
        }
      });
    }

    for (const del of deletes) {
      urlParams.delete(del);
    }

    for (const move of moves) {
      if (!move.base) {
        const urlName = this.generateUrlName(move.name);
        const routeData = move.target.replace(/--/g, '-777').split('-');

        if (tempStructure[urlName]) {
          tempStructure[urlName].currentComponent = routeData[0];
          tempStructure[urlName].params = routeData.splice(1);
        }
        urlParams.append(urlName, this.codec.encodeValue(move.target));
      } else {
        urlParams = new URLSearchParams();
        const urlName = this.generateUrlName(move.name);
        const routeData = move.target.replace(/--/g, '-777').split('-');

        if (tempStructure[urlName]) {
          tempStructure[urlName].currentComponent = routeData[0];
          tempStructure[urlName].params = routeData.splice(1);
        }
        urlParams.append(urlName, this.codec.encodeValue(move.target));

        return move.base + '?' + urlParams.toString();
      }
    }

    return [location.protocol, '//', location.host, location.pathname].join('') + '?' + urlParams.toString();
  }

  public readUrl(): void {
    const urlParams: URLSearchParams = new URLSearchParams(window.location.search);

    urlParams.forEach((value, key) => {
      if (this.currentRouteStructure.hasOwnProperty(key)) {
        const routeData = this.codec.decodeValue(urlParams.get(key)).replace(/--/g, '-777').split('-');
        this.currentRouteStructure[key].currentComponent = routeData[0];
        this.currentRouteStructure[key].params = routeData.splice(1);
      }
    });

    this.structureObservable.next(this.currentRouteStructure);
  }

  public navigateEmbed(moves: { name: string, target: string, base?: string }[]): void {
    let urlParams: URLSearchParams = new URLSearchParams(window.location.search);
    const deletes: string[] = [];
    for (const move of moves) {
      const urlName = this.generateUrlName(move.name);

      urlParams.forEach((value, key) => {
        if (key === urlName) {
          deletes.push(key);
        }
      });
    }

    for (const del of deletes) {
      urlParams.delete(del);
    }

    for (const move of moves) {
      if (!move.base) {
        const urlName = this.generateUrlName(move.name);
        const routeData = move.target.replace(/--/g, '-777').split('-');

        if (this.currentRouteStructure[urlName]) {
          this.currentRouteStructure[urlName].currentComponent = routeData[0];
          this.currentRouteStructure[urlName].params = routeData.splice(1);
        }

        urlParams.append(urlName, this.codec.encodeValue(move.target));
      } else {
        urlParams = new URLSearchParams();
        const urlName = this.generateUrlName(move.name);
        const routeData = move.target.replace(/--/g, '-777').split('-');

        if (this.currentRouteStructure[urlName]) {
          this.currentRouteStructure[urlName].currentComponent = routeData[0];
          this.currentRouteStructure[urlName].params = routeData.splice(1);
        }
        urlParams.append(urlName, this.codec.encodeValue(move.target));

        window.location.href = move.base + '?' + urlParams.toString();
        return;
      }
    }
    window.history.pushState({}, '', [location.protocol, '//', location.host, location.pathname].join('') + '?' + urlParams.toString());
    this.readUrl();
  }

  public getStructureBehaviorSubject(): BehaviorSubject<{ [embedUrlName: string]: EtsComponentRouterData}> {
    return this.structureObservable;
  }

  public resetToHome(): void {
    const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
    for (const k in this.currentRouteStructure) {
      if (this.currentRouteStructure.hasOwnProperty(k)) {
        if (urlParams.has(k)) {
          urlParams.delete(k);
        }
        const route = this.currentRouteStructure[k];
        route.currentComponent = '';
      }
    }

    window.location.href = [location.protocol, '//', location.host, location.pathname].join('');
  }

  private resetKnownRoutes(): void {
    for (const k in this.currentRouteStructure) {
      if (this.currentRouteStructure.hasOwnProperty(k)) {
        const route = this.currentRouteStructure[k];
        route.currentComponent = '';
      }
    }
  }

  /**
   * Redirects user to given URL
   * @param url string
   */
  public redirectToUrl(url: string): void
  {
    window.location.href = url;
  }

  public registerPopState(): void {
    window.addEventListener('popstate', () => {
      this.resetKnownRoutes();
      this.readUrl();
    });
  }
}
